export const LEFT_SIDEPANEL_TOGGLE = 'LEFT_SIDEPANEL_TOGGLE'
export const LEFT_DRAWER_MOBILE_TOGGLE = 'LEFT_DRAWER_MOBILE_TOGGLE'

export const RIGHT_SIDEPANEL_TOGGLE = 'RIGHT_SIDEPANEL_TOGGLE'
export const RIGHT_SIDEPANEL_ACTIVE_CONTENT = 'RIGHT_SIDEPANEL_ACTIVE_CONTENT'
export const CREATE_VISIT_REPORT = 'CREATE_VISIT_REPORT'
export const BUTTON_CHANGE = 'BUTTON_CHANGE'
export const RENAME_REPORT = 'RENAME_REPORT'
export const RENAME_FOLDER = 'RENAME_FOLDER'
export const DELETE_REPORT = 'DELETE_REPORT'


export const CHANGE_THEME = 'CHANGE_THEME'
export const CHANGE_PALETTE = 'CHANGE_PALETTE'

export const ADD_CONNECTED_DB = 'ADD_CONNECTED_DB'

export const UPDATE_NETWORK_GRAPH_HTML = 'UPDATE_NETWORK_GRAPH_HTML'
export const UPDATE_NETWORK_GRAPH_LOADING = 'UPDATE_NETWORK_GRAPH_LOADING'
export const UPDATE_NETWORK_GRAPH_BRAND_OPTIONS = 'UPDATE_NETWORK_GRAPH_BRAND_OPTIONS'
export const UPDATE_NETWORK_GRAPH_BRAND_TYPE_OPTIONS = 'UPDATE_NETWORK_GRAPH_BRAND_TYPE_OPTIONS'
export const UPDATE_NETWORK_GRAPH_TYPE_OPTIONS = 'UPDATE_NETWORK_GRAPH_TYPE_OPTIONS'

export const CHANGE_HOME_SELECTED = 'CHANGE_HOME_SELECTED'
export const UPDATE_HOME_LOADING = 'UPDATE_HOME_LOADING'

export const TOGGLE_MODAL = 'TOGGLE_MODAL'
export const CHANGE_LOGIN_REGISTER_STATE = 'CHANGE_LOGIN_REGISTER_STATE'
export const CHANGE_USERNAME = 'CHANGE_USERNAME'
export const CHANGE_FIRST_LOGIN_STATUS = 'CHANGE_FIRST_LOGIN_STATUS'
export const CHANGE_VERSION = 'CHANGE_VERSION'
export const CHANGE_SSOLOGIN = 'CHANGE_SSOLOGIN'
export const CHECK_PARTNER_LOGIN = 'CHECK_PARTNER_LOGIN'
export const ADD_PARTNER_USER_DETAILS = 'ADD_PARTNER_USER_DETAILS'

export const ADD_EXPLORE_REPORT_DATA = 'ADD_EXPLORE_REPORT_DATA'
export const ADD_AGGREGATED_VISITATION_COUNT = 'ADD_AGGREGATED_VISITATION_COUNT'
export const ADD_EXPLORE_MERGED_REPORT_DATA = 'ADD_EXPLORE_MERGED_REPORT_DATA'
export const UPDATE_EXPLORE_REPORT_DATA = 'UPDATE_EXPLORE_REPORT_DATA'
export const UPDATE_EXPLORE_SAVE_CONFIG = 'UPDATE_EXPLORE_SAVE_CONFIG'
export const SELECT_EXPLORE_SIDEPANEL = 'SELECT_EXPLORE_SIDEPANEL'
export const UPDATE_EXPLORE_SIDEPANEL = 'UPDATE_EXPLORE_SIDEPANEL'
export const ADD_ROOT_FOLDER_ID = 'ADD_ROOT_FOLDER_ID'
export const UPDATE_EXPLORE_LOADING = 'UPDATE_EXPLORE_LOADING'
export const UPDATE_EXPLORE_REPORT_LOADING = 'UPDATE_EXPLORE_REPORT_LOADING'
export const UPDATE_EXPLORE_REPORT_ERROR = 'UPDATE_EXPLORE_REPORT_ERROR'
export const UPDATE_PARENT_CHILD_EXPLORE_SIDEPANEL = 'UPDATE_PARENT_CHILD_EXPLORE_SIDEPANEL'
export const ADD_EXPLORE_LAYER = 'ADD_EXPLORE_LAYER'
export const UPDATE_EXPLORE_LAYER = 'UPDATE_EXPLORE_LAYER'
export const DELETE_EXPLORE_LAYER = 'DELETE_EXPLORE_LAYER'
export const MERGE_LAYER = 'MERGE_LAYER'
export const UNMERGE_LAYER = 'UNMERGE_LAYER'
export const REORDER_EXPLORE_LAYERS = 'REORDER_EXPLORE_LAYERS'
export const UPDATE_MAP_STYLE = 'UPDATE_MAP_STYLE'

export const UPDATE_EXPLORE_DOWNLOAD_PROGRESS = 'UPDATE_EXPLORE_DOWNLOAD_PROGRESS'
export const UPDATE_LOADING_TEXT = 'UPDATE_LOADING_TEXT'

export const OPEN_EXPLORE_CONTEXT_MENU = 'OPEN_EXPLORE_CONTEXT_MENU'
export const CLOSE_EXPLORE_CONTEXT_MENU = 'CLOSE_EXPLORE_CONTEXT_MENU'
export const CREATE_FOLDER = 'CREATE_FOLDER'
export const DELETE_FOLDER = 'DELETE_FOLDER'
export const ADD_REPORT_TO_FOLDER = 'ADD_REPORT_TO_FOLDER'
export const REMOVE_REPORT_FROM_FOLDER = 'REMOVE_REPORT_FROM_FOLDER'
export const MIGRATE_REPORT_TO_ANOTHER_FOLDER = 'MIGRATE_REPORT_TO_ANOTHER_FOLDER'
export const INCREMENT_LAST_FOLDER_INDEX = 'INCREMENT_LAST_FOLDER_INDEX'

export const UPDATE_MONITOR_SIDEPANEL = 'UPDATE_MONITOR_SIDEPANEL'
export const UPDATE_MONITOR_LOADING = 'UPDATE_MONITOR_LOADING'
export const UPDATE_MONITOR_DATA_LOADING = 'UPDATE_MONITOR_DATA_LOADING'
export const UPDATE_MONITOR_DATA_ERROR = 'UPDATE_MONITOR_DATA_ERROR'
export const UPDATE_PARENT_CHILD_MONITOR_SIDEPANEL = 'UPDATE_PARENT_CHILD_MONITOR_SIDEPANEL'
export const UPDATE_MONITOR_DATE_RANGE = 'UPDATE_MONITOR_DATE_RANGE'
export const UPDATE_MONITOR_COMPARSION_DATE_RANGE = 'UPDATE_MONITOR_COMPARSION_DATE_RANGE'
export const UPDATE_MONITOR_SUMMARIZED_DATA = 'UPDATE_MONITOR_SUMMARIZED_DATA'
export const UPDATE_MONITOR_COMPARISON_SUMMARIZED_DATA = 'UPDATE_MONITOR_COMPARISON_SUMMARIZED_DATA'
export const UPDATE_MONITOR_DATA = 'UPDATE_MONITOR_DATA'
export const UPDATE_MONITOR_SUMMARIZED_CARDS = 'UPDATE_MONITOR_SUMMARIZED_CARDS'
export const UPDATE_MONITOR_CHARTS = 'UPDATE_MONITOR_CHARTS'
export const SELECT_MONITOR_SIDEPANEL = 'SELECT_MONITOR_SIDEPANEL'
export const UPDATE_ADSET_COMPARISION_SELECTED_VALUE = 'UPDATE_ADSET_COMPARISION_SELECTED_VALUE'
export const UPDATE_ADSET_COMPARISION_SUMMARIZED = 'UPDATE_ADSET_COMPARISION_SUMMARIZED'
export const REMOVE_ADSET_COMPARISION_SUMMARIZED = 'REMOVE_ADSET_COMPARISION_SUMMARIZED'
export const EXPAND_LIST_MONITOR_SIDEPANEL = 'EXPAND_LIST_MONITOR_SIDEPANEL'
export const UPDATE_MONITOR_SIDEPANEL_CONTENT = 'UPDATE_MONITOR_SIDEPANEL_CONTENT'

export const UPDATE_OPTIMIZE_DATA = 'UPDATE_OPTIMIZE_DATA'
export const UPDATE_OPTIMIZE_DATE_RANGE = 'UPDATE_OPTIMIZE_DATE_RANGE'
export const UPDATE_OPTIMIZE_SIDEPANEL = 'UPDATE_OPTIMIZE_SIDEPANEL'
export const UPDATE_OPTIMIZE_LOADING = 'UPDATE_OPTIMIZE_LOADING'
export const UPDATE_OPTIMIZE_DATA_LOADING = 'UPDATE_OPTIMIZE_DATA_LOADING'
export const UPDATE_OPTIMIZE_DATA_ERROR = 'UPDATE_OPTIMIZE_DATA_ERROR'
export const UPDATE_OPTIMIZE_CHARTS = 'UPDATE_OPTIMIZE_CHARTS'
export const UPDATE_PARENT_CHILD_OPTIMIZE_SIDEPANEL = 'UPDATE_PARENT_CHILD_OPTIMIZE_SIDEPANEL'
export const SELECT_OPTIMIZE_SIDEPANEL = 'SELECT_OPTIMIZE_SIDEPANEL'
export const EXPAND_LIST_OPTIMIZE_SIDEPANEL = 'EXPAND_LIST_OPTIMIZE_SIDEPANEL'

export const CHANGE_SEARCH_QUERY = 'CHANGE_SEARCH_QUERY'
export const CHANGE_SEARCH_DATABASE = 'CHANGE_SEARCH_DATABASE'
export const UPDATE_SEARCH_DATA = 'UPDATE_SEARCH_DATA'
export const TOGGLE_SEARCH_LOADING = 'UPDATE_SEARCH_DATA_LOADING'
export const UPDATE_CHAT = 'UPDATE_CHAT'
export const TOGGLE_SEARCH_ERROR = 'UPDATE_SEARCH_DATA_ERROR'

export const CHANGE_SETTINGS_SELECTED = 'CHANGE_SETTINGS_SELECTED'
export const UPDATE_SETTINGS_DATA = 'UPDATE_SETTINGS_DATA'
export const UPDATE_SETTINGS_USER_DATA = 'UPDATE_SETTINGS_USER_DATA'
export const UPDATE_SETTINGS_COMPANY_DATA = 'UPDATE_SETTINGS_COMPANY_DATA'
export const UPDATE_SETTINGS_PLAN_DATA = 'UPDATE_SETTINGS_PLAN_DATA'
export const UPDATE_SETTINGS_SOCIAL_DATA = 'UPDATE_SETTINGS_SOCIAL_DATA'
export const UPDATE_SETTINGS_ACCOUNTS = 'UPDATE_SETTINGS_ACCOUNTS'
export const UPDATE_SETTINGS_SOCIAL_ACCOUNTS = 'UPDATE_SETTINGS_SOCIAL_ACCOUNTS'
export const UPDATE_UPLOADED_AUDIENCE = 'UPDATE_UPLOADED_AUDIENCE'

export const LOGOUT = 'LOGOUT'