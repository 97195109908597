import axios from 'axios'
import { getfromLocalStorage } from '../common/functions'

let host = process.env.REACT_APP_TEST_URL
const sherlockTestAxios = axios.create({baseURL:host})

// Add a request interceptor
sherlockTestAxios.interceptors.request.use(
    async config => {

        // UPDATE YOUR TOKEN HERE FROM https://sherlockbackendtest.infiniteanalytics.com/docs
        const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTU0MjExNTgsInN1YiI6IjI1NCJ9.YwBGt8OmCLb9ZK6sRpHBFqrQB27u8PO1EffDi2USl3I'

        config.headers['Authorization'] = `Bearer ${token}`
        
        config.headers['Access-Control-Allow-Origin'] = "*"
        if(config.headers['Content-Type'] !== 'multipart/form-data') 
            config.headers['Content-Type'] = 'application/json'
        return config
    },
    error => {
        Promise.reject(error)
    })

export default sherlockTestAxios