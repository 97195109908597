import { AppBar, Drawer, ListItem, MenuItem, Toolbar, Select, TextField, Typography, withStyles, Button, FormControlLabel, MobileStepper, Avatar, LinearProgress, Switch, TableCell, Accordion, AccordionSummary, AccordionDetails, Checkbox, Radio, Grid, List, Tooltip, FormControl, InputLabel, Divider, SvgIcon, ListItemIcon, Slider } from "@material-ui/core"
import { Autocomplete, TreeItem, TreeView } from "@material-ui/lab"
import { checkMuiLightDark, getLightDarkGrayscaleColor } from "../common/functions"

import DarkModeIcon from '../assets/icons/Dark Mode.svg'
import LightModeIcon from '../assets/icons/Light Mode.svg'

const drawerWidth = 'min(350px, calc(200vw/12))'
const drawerWidthMobile = 'max(276px, calc(200vw/12))'

export const CustomWebsiteAppBar = withStyles(theme => ({
    root: {
        zIndex: theme.zIndex.drawer + 1,
        padding: `0 ${theme.spacing(8)}px`,
        [theme.breakpoints.down('sm')]: {
            padding: `0`,
        }
    },
    colorDefault: {
        color: getLightDarkGrayscaleColor(theme, 100, 10),
        backgroundColor: getLightDarkGrayscaleColor(theme, 10, 100, true)
    }
}))(AppBar)

export const CustomAppBar = withStyles(theme => ({
    root: {
        zIndex: theme.zIndex.drawer + 1,
    },
    colorDefault: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.foreground
    }
}))(AppBar)

export const CustomWebsiteToolbar = withStyles(theme => ({
    regular: {
        [theme.breakpoints.up('sm')]: {
            minHeight: theme.spacing(6)
        }
    },
    gutters: {
        paddingLeft: 0,
        paddingRight: 0,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        }
    }
}))(Toolbar)

export const CustomToolbar = withStyles(theme => ({
    gutters: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        // [theme.breakpoints.down('sm')]: {
        //     paddingLeft: theme.spacing(2),
        //     paddingRight: theme.spacing(2),
        // }
    }
}))(Toolbar)

export const CustomDrawer = withStyles(theme => ({
    paperAnchorDockedLeft: {
        backgroundColor: theme.palette.foreground,
        [theme.breakpoints.down('sm')]: {
            width: drawerWidthMobile,
        },
        borderRight: 'none',
        boxShadow: theme.shadows[8],
        overflow: 'hidden'
    },
    paperAnchorLeft: {
        boxShadow: theme.shadows[8],
        backgroundColor: theme.palette.foreground,
        [theme.breakpoints.down('sm')]: {
            width: drawerWidthMobile,
        },
        borderRight: 'none',
        overflow: 'hidden'
    },
    paperAnchorDockedRight: {
        backgroundColor: theme.palette.foreground,
        [theme.breakpoints.down('sm')]: {
            width: drawerWidthMobile,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        borderLeft: 'none',
        boxShadow: theme.shadows[8],
        overflow: 'hidden'
    },
    paperAnchorRight: {
        boxShadow: theme.shadows[8],
        backgroundColor: theme.palette.foreground,
        [theme.breakpoints.down('sm')]: {
            width: drawerWidthMobile,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        borderLeft: 'none',
        overflow: 'hidden'
    }
}))(Drawer)

export const CustomDrawerFilter = withStyles(theme => ({
    modal: {
        '& > div:nth-of-type(1)': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)'
        },
        zIndex: `${theme.zIndex.drawer} !important`
    },
    paperAnchorDockedRight: {
        backgroundColor: theme.palette.foreground,
        width: drawerWidth,
        [theme.breakpoints.down('sm')]: {
            width: drawerWidthMobile,
        },
        borderLeft: 'none',
        boxShadow: theme.shadows[11],
        overflow: 'hidden'
    },
    paperAnchorRight: {
        boxShadow: theme.shadows[11],
        backgroundColor: theme.palette.foreground,
        [theme.breakpoints.down('sm')]: {
            width: drawerWidthMobile,
        },
        borderLeft: 'none',
        overflow: 'hidden'
    }
}))(Drawer)

export const CustomGrid = withStyles(theme => ({
    'spacing-xs-1': {
        width: '100%',
        margin: 0
    },
    'spacing-xs-2': {
        width: '100%',
        margin: 0
    }
}))(Grid)

export const CustomGridContentItems = withStyles(theme => ({
    // 'spacing-xs-1': {
    //     margin: `0px -${theme.spacing(0.5)}px`,
    // },
    'item': {
        // padding: `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0px !important`
        padding: `0px ${theme.spacing(0.5)}px !important`
    }
}))(Grid)

export const CustomGridContent = withStyles(theme => ({
    'spacing-xs-1': {
        width: `calc(100% + ${theme.spacing(0.5)}px)`,
        margin: `+${theme.spacing(1)}px -${theme.spacing(0.5)}px`,
    },
    'spacing-xs-2': {
        width: `calc(100% + ${theme.spacing(1)}px)`,
        margin: `+${theme.spacing(1)}px -${theme.spacing(1)}px`
    },
}))(Grid)

export const CustomFormControl = withStyles(theme => ({
    root: {
        '& > div': {
            backgroundColor: '#EDEDED'
        },
    },
}))(FormControl)

export const CustomFormControlFilled = withStyles(theme => ({
    root: {
        backgroundColor: getLightDarkGrayscaleColor(theme, '90', '70'),
        paddingTop: theme.spacing(1)
    }
}))(FormControl)

export const CustomSelectBodyPrimary = withStyles(theme => ({
    root: {
        ...theme.typography.body1,
        fontWeight: theme.typography.fontWeightMedium,
        paddingLeft: theme.spacing(1),
    },
    nativeInput: {
        width: 'max-content !important'
    }
}))(Select)

export const CustomMenuItemBodyPrimary = withStyles(theme => ({
    root: {
        ...theme.typography.body1,
        fontWeight: theme.typography.fontWeightMedium,
        transition: 'all 0.3s ease-in-out',
        '&:hover, &.Mui-focusVisible, &.Mui-selected': {
            color: theme.palette.primary.contrastText
        },
    },
}))(MenuItem)

export const CustomMenuItemLabelLarge = withStyles(theme => ({
    root: {
        ...theme.typography.labelLarge ?? theme.typography.body1,
        fontWeight: theme.typography.fontWeightMedium,
        transition: 'all 0.3s ease-in-out',
        '&:hover, &.Mui-focusVisible, &.Mui-selected': {
            color: theme.palette.primary.contrastText
        },
    },
}))(MenuItem)

export const CustomInputLabelLabelMedium = withStyles(theme => ({
    root: {
        ...theme.typography.labelMedium ?? theme.typography.body1,
    },
}))(InputLabel)

export const CustomDivider = withStyles(_ => ({
    root: {
        height: '4px'
    }
}))(Divider)

export const CustomDividerModalColor = withStyles(theme => ({
    root: {
        backgroundColor: `${theme.palette.grayscaleLight['60'] ?? theme.palette.background}`,
        height: '4px'
    }
}))(Divider)

export const CustomDividerColor = withStyles(theme => ({
    root: {
        backgroundColor: `${theme.palette.grayscaleLight['60'] ?? theme.palette.background}`,
        height: '2px'
    }
}))(Divider)

export const CustomDividerTertiary = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.tertiary.main ?? theme.palette.primary.main,
        height: '2px'
    }
}))(Divider)

export const CustomDividerQuarternary = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.quarternary.main ?? theme.palette.primary.main,
        height: '2px'
    }
}))(Divider)

export const DisplayPrimary = withStyles(theme => ({
    body1: {
        ...theme.typography.displayPrimary ?? theme.typography.body1,
        display: 'flex',
        alignItems: 'center'
    }
}))(Typography)

export const DisplayPrimaryMedium = withStyles(theme => ({
    body1: {
        ...theme.typography.displayPrimary ?? theme.typography.body1,
        fontWeight: theme.typography.fontWeightMedium,
        display: 'flex',
        alignItems: 'center'
    }
}))(Typography)

export const DisplayPrimarySemiBold = withStyles(theme => ({
    body1: {
        ...theme.typography.displayPrimary ?? theme.typography.body1,
        fontWeight: theme.typography.fontWeightSemiBold ?? theme.typography.fontWeightMedium,
        display: 'flex',
        alignItems: 'center'
    }
}))(Typography)

export const DisplaySecondary = withStyles(theme => ({
    body1: {
        ...theme.typography.displaySecondary ?? theme.typography.body2,
        display: 'flex',
        alignItems: 'center'
    }
}))(Typography)

export const DisplaySecondaryMedium = withStyles(theme => ({
    body1: {
        ...theme.typography.displaySecondary ?? theme.typography.body2,
        fontWeight: theme.typography.fontWeightMedium,
        display: 'flex',
        alignItems: 'center'
    }
}))(Typography)

export const DisplaySecondarySemiBold = withStyles(theme => ({
    body1: {
        ...theme.typography.displaySecondary ?? theme.typography.body2,
        fontWeight: theme.typography.fontWeightSemiBold ?? theme.typography.fontWeightMedium,
        display: 'flex',
        alignItems: 'center'
    }
}))(Typography)

export const TypographyMedium = withStyles(theme => ({
    h1: {
        ...theme.typography.h1,
        fontWeight: theme.typography.fontWeightMedium,
        display: 'flex',
        alignItems: 'center'
    },
    h2: {
        ...theme.typography.h2,
        fontWeight: theme.typography.fontWeightMedium,
        display: 'flex',
        alignItems: 'center'
    },
    h3: {
        ...theme.typography.h3,
        fontWeight: theme.typography.fontWeightMedium,
        display: 'flex',
        alignItems: 'center'
    },
    body1: {
        ...theme.typography.body1,
        fontWeight: theme.typography.fontWeightMedium,
        display: 'flex',
        alignItems: 'center'
    },
    body2: {
        ...theme.typography.body2,
        fontWeight: theme.typography.fontWeightMedium,
        display: 'flex',
        alignItems: 'center'
    }
}))(Typography)

export const TypographySemiBold = withStyles(theme => ({
    h1: {
        ...theme.typography.h1,
        fontWeight: theme.typography.fontWeightSemiBold ?? theme.typography.fontWeightMedium,
        display: 'flex',
        alignItems: 'center'
    },
    h2: {
        ...theme.typography.h2,
        fontWeight: theme.typography.fontWeightSemiBold ?? theme.typography.fontWeightMedium,
        display: 'flex',
        alignItems: 'center'
    },
    h3: {
        ...theme.typography.h3,
        fontWeight: theme.typography.fontWeightSemiBold ?? theme.typography.fontWeightMedium,
        display: 'flex',
        alignItems: 'center'
    },
    body1: {
        ...theme.typography.body1,
        fontWeight: theme.typography.fontWeightSemiBold ?? theme.typography.fontWeightMedium,
        display: 'flex',
        alignItems: 'center'
    },
    body2: {
        ...theme.typography.body2,
        fontWeight: theme.typography.fontWeightSemiBold ?? theme.typography.fontWeightMedium,
        display: 'flex',
        alignItems: 'center'
    }
}))(Typography)

export const LabelSmall = withStyles(theme => ({
    body1: {
        ...theme.typography.labelSmall ?? theme.typography.body1,
        display: 'flex',
        alignItems: 'center'
    }
}))(Typography)

export const LabelMedium = withStyles(theme => ({
    body1: {
        ...theme.typography.labelMedium ?? theme.typography.body1,
        display: 'flex',
        alignItems: 'center'
    }
}))(Typography)

export const LabelLarge = withStyles(theme => ({
    body1: theme.typography.labelLarge ?? theme.typography.body1
}))(Typography)

export const Heading1 = withStyles(theme => ({
    body1: theme.typography.heading1
}))(Typography)

export const Heading2 = withStyles(theme => ({
    body1: theme.typography.heading2
}))(Typography)

export const Paragraph1 = withStyles(theme => ({
    body1: theme.typography.paragraph1
}))(Typography)

export const Paragraph2 = withStyles(theme => ({
    body1: theme.typography.paragraph2
}))(Typography)

export const Paragraph3 = withStyles(theme => ({
    body1: theme.typography.paragraph3
}))(Typography)

export const Paragraph4 = withStyles(theme => ({
    body1: theme.typography.paragraph4
}))(Typography)

export const Paragraph5 = withStyles(theme => ({
    body1: {
        ...theme.typography.paragraph5,
        display: 'flex',
        alignItems: 'center'
    }
}))(Typography)

export const CustomButton = withStyles(theme => ({
    root: {
        color: theme.palette.primary.contrastText,
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    },
}))(Button)

export const CustomPrimaryButton = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
        '&:active': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
}))(CustomButton)

export const CustomSecondaryButton = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
        },
        '&:active': {
            backgroundColor: theme.palette.secondary.dark,
        },
    },
}))(CustomButton)

export const CustomTertiaryButton = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.tertiary?.main ?? theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.tertiary?.light ?? theme.palette.primary.main,
        },
        '&:active': {
            backgroundColor: theme.palette.tertiary?.dark ?? theme.palette.primary.main,
        },
    },
}))(CustomButton)

export const CustomQuarternaryButton = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.quarternary?.main ?? theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.quarternary?.light ?? theme.palette.primary.main,
        },
        '&:active': {
            backgroundColor: theme.palette.quarternary?.dark ?? theme.palette.primary.main,
        },
    },
}))(CustomButton)

export const CustomDashboardSecondaryLandingButton = withStyles(theme => ({
    contained: {
        ...theme.typography.h2,
    },
}))(CustomSecondaryButton)

export const CustomDashboardTertiaryLandingButton = withStyles(theme => ({
    contained: {
        ...theme.typography.h2,
    },
}))(CustomTertiaryButton)

export const CustomDashboardQuarternaryLandingButton = withStyles(theme => ({
    contained: {
        ...theme.typography.h2,
    },
}))(CustomQuarternaryButton)

export const CustomMenuItem = withStyles(theme => ({
    selected: {},
    root: {
        ...theme.typography.paragraph4,
        '&:hover': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.light,
        },
        '&:active': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.dark,
        },
        '&$selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '&:hover': {
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.light,
            },
            '&:active': {
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.dark,
            },
        },
        '&.Mui-focusVisible': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        }
    }
}))(MenuItem)

export const CustomSecondaryMenuItem = withStyles(theme => ({
    selected: {},
    root: {
        ...theme.typography.paragraph5,
        '&:hover': {
            color: theme.palette.secondary.contrastText,
            backgroundColor: theme.palette.secondary.light,
        },
        '&:active': {
            color: theme.palette.secondary.contrastText,
            backgroundColor: theme.palette.secondary.dark,
        },
        '&$selected': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            '&:hover': {
                color: theme.palette.secondary.contrastText,
                backgroundColor: theme.palette.secondary.light,
            },
            '&:active': {
                color: theme.palette.secondary.contrastText,
                backgroundColor: theme.palette.secondary.dark,
            },
        },
        '&.Mui-focusVisible': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
        }
    }
}))(MenuItem)

export const CustomTertiaryMenuItem = withStyles(theme => ({
    selected: {},
    root: {
        ...theme.typography.paragraph4,
        '&:hover': {
            color: theme.palette.tertiary.contrastText,
            backgroundColor: theme.palette.tertiary.light,
        },
        '&:active': {
            color: theme.palette.tertiary.contrastText,
            backgroundColor: theme.palette.tertiary.dark,
        },
        '&$selected': {
            backgroundColor: theme.palette.tertiary.main,
            color: theme.palette.tertiary.contrastText,
            '&:hover': {
                color: theme.palette.tertiary.contrastText,
                backgroundColor: theme.palette.tertiary.light,
            },
            '&:active': {
                color: theme.palette.tertiary.contrastText,
                backgroundColor: theme.palette.tertiary.dark,
            },
        },
        '&.Mui-focusVisible': {
            backgroundColor: theme.palette.tertiary.main,
            color: theme.palette.tertiary.contrastText,
        }
    }
}))(MenuItem)

export const CustomQuarternaryMenuItem = withStyles(theme => ({
    selected: {},
    root: {
        ...theme.typography.paragraph4,
        '&:hover': {
            color: theme.palette.quarternary.contrastText,
            backgroundColor: theme.palette.quarternary.light,
        },
        '&:active': {
            color: theme.palette.quarternary.contrastText,
            backgroundColor: theme.palette.quarternary.dark,
        },
        '&$selected': {
            backgroundColor: theme.palette.quarternary.main,
            color: theme.palette.quarternary.contrastText,
            '&:hover': {
                color: theme.palette.quarternary.contrastText,
                backgroundColor: theme.palette.quarternary.light,
            },
            '&:active': {
                color: theme.palette.quarternary.contrastText,
                backgroundColor: theme.palette.quarternary.dark,
            },
        },
        '&.Mui-focusVisible': {
            backgroundColor: theme.palette.quarternary.main,
            color: theme.palette.quarternary.contrastText,
        }
    }
}))(MenuItem)

export const CustomTreeview = withStyles(theme => ({
    root: {
        padding: `0 ${theme.spacing(3)}px`,
        height: '100%',
    },
}))(TreeView)

export const CustomTreeViewItem = withStyles(theme => ({
    root: {
        '&.Mui-selected > div :nth-child(2)': {
            backgroundColor: `${(theme.palette.tertiary ?? theme.palette.primary).main} !important`,
            color: theme.palette.primary.contrastText
        },
        '&:focus': {
            '& > div :nth-child(2)': {
                backgroundColor: 'inherit !important',
            }
        },
    },
    label: {
        ...theme.typography.labelMedium ?? theme.typography.body1,
        paddingLeft: 0,
        '&:hover': {
            backgroundColor: 'inherit'
        },
        padding: `${theme.spacing(1)}px 0`,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    group: {
        marginLeft: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        borderLeft: `1px dashed ${theme.palette.text.disabled}`
    },
    content: {
        flexDirection: 'row-reverse',
    }
}))(TreeItem)

export const CustomCheckBoxSidepanel = withStyles((theme) => ({
    root: {
        padding: `0 ${theme.spacing(1)}px 0 0`,
        '& svg': {
            fontSize: theme.typography.h3.fontSize,
        }
    },
    colorPrimary: {
        '&.Mui-checked': {
            color: (theme.palette.tertiary ?? theme.palette.primary).main
        }
    },
    colorSecondary: {
        '&.Mui-checked': {
            color: (theme.palette.quarternary ?? theme.palette.primary).main
        }
    },
}))(Checkbox)

export const CustomMenuItemParagraph1 = withStyles(theme => ({
    root: {
        ...theme.typography.paragraph1,
    }
}))(CustomMenuItem)

export const CustomMenuItemParagraph4 = withStyles(theme => ({
    root: {
        ...theme.typography.paragraph4,
    }
}))(CustomMenuItem)

export const CustomMenuItemParagraph5 = withStyles(theme => ({
    root: {
        ...theme.typography.paragraph5,
    }
}))(CustomMenuItem)

export const CustomDrawerList = withStyles(theme => ({
    padding: {
        paddingTop: theme.spacing(1.25),
    },
}))(List)

export const CustomDrawerListContent = withStyles(_ => ({
    root: {
        overflowY: 'auto',
        minHeight: '0px',
    },
}))(CustomDrawerList)

const ListItemBasicCss = withStyles(theme => ({
    root: {
        ...theme.typography.paragraph3,
    },
    gutters: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    button: {
        '&:hover': {
            backgroundColor: 'transparent',
            '&::after': {
                width: theme.spacing(0.5),
            },
            color: theme.palette.primary.main,
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            zIndex: -1,
            background: theme.palette.primary.main,
            top: 0,
            bottom: 0,
            right: 0,
            width: 0,
            transition: 'all 0.5s',
            WebkitTransition: 'all 0.5s',
        },
        '&.Mui-selected': {
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'transparent',
        }
    },
}))(ListItem)

export const CustomPrimaryDrawerListItem = withStyles(theme => ({
    root: {
        '&:hover': {
            color: theme.palette.primary.main,
        },
        '&::after': {
            background: theme.palette.primary.main,
        },
    },
    selected: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.contrastText,
        '&::after': {
            width: '100%',
        },
        '&:hover': {
            '&::after': {
                width: '100%',
            },
            color: theme.palette.primary.contrastText,
        },
    },
}))(ListItemBasicCss)

export const CustomSecondaryDrawerListItem = withStyles(theme => ({
    root: {
        '&:hover': {
            color: theme.palette.secondary.main,
        },

        '&::after': {
            background: theme.palette.secondary.main,
        },
    },
    selected: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.contrastText,
        '&::after': {
            width: '100%',
        },
        '&:hover': {
            '&::after': {
                width: '100%',
            },
            color: theme.palette.primary.contrastText,
        },
    },
}))(ListItemBasicCss)

export const CustomTertiaryDrawerListItem = withStyles(theme => ({
    root: {
        '&:hover': {
            color: theme.palette.tertiary?.main ?? theme.palette.primary.main,
        },

        '&::after': {
            background: theme.palette.tertiary?.main ?? theme.palette.primary.main,
        },
    },
    selected: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.contrastText,
        '&::after': {
            width: '100%',
        },
        '&:hover': {
            '&::after': {
                width: '100%',
            },
            color: theme.palette.primary.contrastText,
        },
    },
}))(ListItemBasicCss)

export const CustomQuarternaryDrawerListItem = withStyles(theme => ({
    root: {
        '&:hover': {
            color: theme.palette.quarternary?.main ?? theme.palette.primary.main,
        },

        '&::after': {
            background: theme.palette.quarternary?.main ?? theme.palette.primary.main,
        },
    },
    selected: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.contrastText,
        '&::after': {
            width: '100%',
        },
        '&:hover': {
            '&::after': {
                width: '100%',
            },
            color: theme.palette.primary.contrastText,
        },
    },
}))(ListItemBasicCss)

export const CustomDefaultDrawerListItem = withStyles(theme => ({
    root: {
        '&:hover': {
            color: theme.palette.text.primary,
        },

        '&::after': {
            background: theme.palette.grayscaleDark?.[checkMuiLightDark(theme, 80, 20)] ?? theme.palette.primary.contrastText,
        },
    },
    selected: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.contrastText,
        '&::after': {
            width: '100%',
        },
        '&:hover': {
            '&::after': {
                width: '100%',
            },
            color: theme.palette.primary.contrastText,
        },
    },
}))(ListItemBasicCss)

export const ListItemBasicCssContent = withStyles(theme => ({
    root: {
        ...theme.typography.labelMedium ?? theme.typography.h1,
        borderTop: `2px solid transparent`,
        borderRight: `2px solid transparent`,
        borderBottom: `2px solid transparent`,
        cursor: 'pointer'
    },
    gutters: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    button: {
        '&:hover': {
            backgroundColor: 'transparent',
            '&::after': {
                width: theme.spacing(1),
            },
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            zIndex: -1,
            background: theme.palette.primary.main,
            top: 0,
            bottom: 0,
            left: 0,
            width: 0,
            transition: 'all 0.5s',
            WebkitTransition: 'all 0.5s',
        },
        '&.Mui-selected': {
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'transparent',
        }
    }
}))(ListItem)

export const CustomPrimaryDrawerListItemContent = withStyles(theme => ({
    root: {
        '&:hover': {
            borderTopColor: theme.palette.primary.main,
            borderRightColor: theme.palette.primary.main,
            borderBottomColor: theme.palette.primary.main,
        },
        '&::after': {
            background: theme.palette.primary.main,
        },
    },
    selected: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.contrastText,
        borderTopColor: theme.palette.primary.main,
        borderRightColor: theme.palette.primary.main,
        borderBottomColor: theme.palette.primary.main,
        '&::after': {
            width: '100%',
        },
        '&:hover': {
            '&::after': {
                width: '100%',
            },
            color: theme.palette.primary.contrastText,
        },
    },
}))(ListItemBasicCssContent)

export const CustomSecondaryDrawerListItemContent = withStyles(theme => ({
    root: {
        '&:hover': {
            borderTopColor: theme.palette.secondary.main,
            borderRightColor: theme.palette.secondary.main,
            borderBottomColor: theme.palette.secondary.main,
        },

        '&::after': {
            background: theme.palette.secondary.main,
        },
    },
    selected: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.contrastText,
        borderTopColor: theme.palette.secondary.main,
        borderRightColor: theme.palette.secondary.main,
        borderBottomColor: theme.palette.secondary.main,
        '&::after': {
            width: '100%',
        },
        '&:hover': {
            '&::after': {
                width: '100%',
            },
            color: theme.palette.primary.contrastText,
        },
    },
}))(ListItemBasicCssContent)

export const CustomTertiaryDrawerListItemContent = withStyles(theme => ({
    root: {
        '&:hover': {
            borderTopColor: theme.palette.tertiary?.main ?? theme.palette.primary.main,
            borderRightColor: theme.palette.tertiary?.main ?? theme.palette.primary.main,
            borderBottomColor: theme.palette.tertiary?.main ?? theme.palette.primary.main,
        },

        '&::after': {
            background: theme.palette.tertiary?.main ?? theme.palette.primary.main,
        },
    },
    selected: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.contrastText,
        borderTopColor: theme.palette.tertiary?.main ?? theme.palette.primary.main,
        borderRightColor: theme.palette.tertiary?.main ?? theme.palette.primary.main,
        borderBottomColor: theme.palette.tertiary?.main ?? theme.palette.primary.main,
        '&::after': {
            width: '100%',
        },
        '&:hover': {
            '&::after': {
                width: '100%',
            },
            color: theme.palette.primary.contrastText,
        },
    },
}))(ListItemBasicCssContent)

export const CustomQuarternaryDrawerListItemContent = withStyles(theme => ({
    root: {
        '&:hover': {
            borderTopColor: theme.palette.quarternary?.main ?? theme.palette.primary.main,
            borderRightColor: theme.palette.quarternary?.main ?? theme.palette.primary.main,
            borderBottomColor: theme.palette.quarternary?.main ?? theme.palette.primary.main,
        },

        '&::after': {
            background: theme.palette.quarternary?.main ?? theme.palette.primary.main,
        },
    },
    selected: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.contrastText,
        borderTopColor: theme.palette.quarternary?.main ?? theme.palette.primary.main,
        borderRightColor: theme.palette.quarternary?.main ?? theme.palette.primary.main,
        borderBottomColor: theme.palette.quarternary?.main ?? theme.palette.primary.main,
        '&::after': {
            width: '100%',
        },
        '&:hover': {
            '&::after': {
                width: '100%',
            },
            color: theme.palette.primary.contrastText,
        },
    },
}))(ListItemBasicCssContent)

export const CustomDefaultDrawerListItemContent = withStyles(theme => ({
    root: {
        '&:hover': {
            borderTopColor: theme.palette.grayscaleDark?.[checkMuiLightDark(theme, 80, 20)] ?? theme.palette.primary.main,
            borderRightColor: theme.palette.grayscaleDark?.[checkMuiLightDark(theme, 80, 20)] ?? theme.palette.primary.main,
            borderBottomColor: theme.palette.grayscaleDark?.[checkMuiLightDark(theme, 80, 20)] ?? theme.palette.primary.main,
        },

        '&::after': {
            background: theme.palette.grayscaleDark?.[checkMuiLightDark(theme, 80, 20)] ?? theme.palette.primary.main
        },
    },
    selected: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.contrastText,
        borderTopColor: theme.palette.grayscaleDark?.[checkMuiLightDark(theme, 80, 20)] ?? theme.palette.primary.main,
        borderRightColor: theme.palette.grayscaleDark?.[checkMuiLightDark(theme, 80, 20)] ?? theme.palette.primary.main,
        borderBottomColor: theme.palette.grayscaleDark?.[checkMuiLightDark(theme, 80, 20)] ?? theme.palette.primary.main,
        '&::after': {
            width: '100%',
        },
        '&:hover': {
            '&::after': {
                width: '100%',
            },
            color: theme.palette.primary.contrastText,
        },
    },
}))(ListItemBasicCssContent)

export const CustomHoverTooltip = withStyles(theme => ({
    tooltip: {
        ...(theme.typography.labelMedium ?? theme.typography.body1)
    },
}))(Tooltip)

export const CustomTooltip = withStyles(theme => ({
    tooltip: {
        ...theme.typography.paragraph3,
    },
}))(Tooltip)

export const CustomPrimaryTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    arrow: {
        color: theme.palette.primary.main,
    },
}))(CustomTooltip)

export const CustomSecondaryTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
    arrow: {
        color: theme.palette.secondary.main,
    },
}))(CustomTooltip)

export const CustomTertiaryTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.tertiary?.main ?? theme.palette.primary.main,
        color: theme.palette.tertiary?.contrastText ?? theme.palette.primary.contrastText,
    },
    arrow: {
        color: theme.palette.tertiary?.main ?? theme.palette.primary.main,
    },
}))(CustomTooltip)

export const CustomQuarternaryTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.quarternary?.main ?? theme.palette.primary.main,
        color: theme.palette.quarternary?.contrastText ?? theme.palette.primary.contrastText,
    },
    arrow: {
        color: theme.palette.quarternary?.main ?? theme.palette.primary.main,
    },
}))(CustomTooltip)

export const CustomDefaultTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.grayscaleDark?.[checkMuiLightDark(theme, 80, 20)] ?? theme.palette.primary.contrastText,
        color: theme.palette.quarternary?.contrastText ?? theme.palette.primary.contrastText,
    },
    arrow: {
        color: theme.palette.grayscaleDark?.[checkMuiLightDark(theme, 80, 20)] ?? theme.palette.primary.contrastText,
    },
}))(CustomTooltip)

export const CustomSvgIcon = withStyles(theme => ({
    root: {
        width: '24px',
        height: '24px',
    },
}))(SvgIcon)

export const CustomSvgIcon20 = withStyles(theme => ({
    root: {
        width: '20px',
        height: '20px',
    },
}))(SvgIcon)

export const CustomSvgIconHeading2 = withStyles(theme => ({
    root: {
        width: `calc(${(theme.typography.h2 ?? theme.typography.body1).lineHeight} + 1px)` ?? '24px',
        height: `calc(${(theme.typography.h2 ?? theme.typography.body1).lineHeight} + 1px)` ?? '24px',
    },
}))(SvgIcon)

export const CustomSvgIconHeading3 = withStyles(theme => ({
    root: {
        width: `calc(${(theme.typography.h3 ?? theme.typography.body1).lineHeight} + 1px)` ?? '24px',
        height: `calc(${(theme.typography.h3 ?? theme.typography.body1).lineHeight} + 1px)` ?? '24px',
    },
}))(SvgIcon)

export const CustomSvgIconBodyPrimary = withStyles(theme => ({
    root: {
        width: `calc(${(theme.typography.body1 ?? theme.typography.body1).lineHeight} + 1px)` ?? '20px',
        height: `calc(${(theme.typography.body1 ?? theme.typography.body1).lineHeight} + 1px)` ?? '20px',
    },
}))(SvgIcon)

export const CustomSvgIconBodySecondary = withStyles(theme => ({
    root: {
        width: `calc(${(theme.typography.body2 ?? theme.typography.body1).lineHeight} + 1px)` ?? '24px',
        height: `calc(${(theme.typography.body2 ?? theme.typography.body1).lineHeight} + 1px)` ?? '24px',
    },
}))(SvgIcon)

export const CustomSvgIconLabelLarge = withStyles(theme => ({
    root: {
        width: `calc(${(theme.typography.labelLarge ?? theme.typography.body1).lineHeight} + 1px)` ?? '24px',
        height: `calc(${(theme.typography.labelLarge ?? theme.typography.body1).lineHeight} + 1px)` ?? '24px',
    },
}))(SvgIcon)

export const CustomSvgIconLabelMedium = withStyles(theme => ({
    root: {
        width: `calc(${(theme.typography.labelMedium ?? theme.typography.body1).lineHeight} + 1px)` ?? '24px',
        height: `calc(${(theme.typography.labelMedium ?? theme.typography.body1).lineHeight} + 1px)` ?? '24px',
    },
}))(SvgIcon)

export const CustomSvgIconLabelSmall = withStyles(theme => ({
    root: {
        width: `calc(${(theme.typography.labelSmall ?? theme.typography.body1).lineHeight})` ?? '16px',
        height: `calc(${(theme.typography.labelSmall ?? theme.typography.body1).lineHeight})` ?? '16px',
    },
}))(SvgIcon)

export const CustomSwitchLabelMedium = withStyles((theme) => ({
    root: {
        width: `calc(${(theme.typography.labelMedium ?? theme.typography.body1).lineHeight}*2)` ?? '32px',
        height: `calc(${(theme.typography.labelMedium ?? theme.typography.body1).lineHeight})` ?? '16px',
        padding: 0,
        margin: 0,
    },
    switchBase: {
        padding: '3px',
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.grayscaleLight?.['100'] ?? theme.palette.primary.main,
            '& + $track': {
                backgroundColor: getLightDarkGrayscaleColor(theme, '40', '90'),
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: theme.palette.grayscaleLight?.['100'] ?? theme.palette.primary.main,
        },
    },
    thumb: {
        width: `calc(${(theme.typography.labelMedium ?? theme.typography.body1).lineHeight} - 6px)` ?? '10px',
        height: `calc(${(theme.typography.labelMedium ?? theme.typography.body1).lineHeight} - 6px)` ?? '10px',
        color: theme.palette.grayscaleLight?.['100'] ?? theme.palette.primary.main,
    },
    track: {
        // backgroundImage: `url('${LightModeIcon}'), url('${DarkModeIcon}')`,
        // backgroundRepeat: 'no-repeat',
        // backgroundSize: `calc(${(theme.typography.labelMedium ?? theme.typography.body1).lineHeight} - 1px)` ?? '10px',
        backgroundPosition: `center left, center right`,
        borderRadius: `calc(${(theme.typography.labelMedium ?? theme.typography.body1).lineHeight})` ?? '16px',
        // backgroundColor: theme.palette.grayscaleLight?.['40'] ?? theme.palette.primary.main,
        backgroundColor: getLightDarkGrayscaleColor(theme, '40', '40'),
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(Switch)

export const CustomBodySecondaryAutoComplete = withStyles(theme => ({
    root: {
        '& input': {
            ...theme.typography.body2,
            fontWeight: theme.typography.fontWeightMedium,
        },
        '& > div': {
            marginTop: `${theme.spacing(1)}px !important`,
            marginBottom: `0 !important`
        },
    },
}))(Autocomplete)

export const CustomSearchTextField = withStyles(theme => ({
    root: {
        '& > div': {
            borderRadius: theme.shape.borderRadius,
            '& > input': {
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1),
            },
            '&::before, &::after, &:hover::before': {
                border: 'none',
            }
        },
    },
}))(TextField)

export const CustomListItemPlans = withStyles(theme => ({
    root: {
    },
    gutters: {
        paddingLeft: 0,
        paddingRight: 0,
    },
}))(ListItem)

export const CustomListItemIconPlans = withStyles(theme => ({
    root: {
        minWidth: 'fit-content',
        marginRight: theme.spacing(1.5)
    }
}))(ListItemIcon)

export const CustomDrawerSecondaryListButton = withStyles(theme => ({
    root: {
        ...theme.typography.paragraph3,
    },
    gutters: {
        paddingLeft: theme.spacing(4.5),
        paddingRight: theme.spacing(3),
    },
    button: {
        '&:hover': {
            color: theme.palette.secondary.contrastText,
            backgroundColor: theme.palette.secondary.light,
        },
        '&:active': {
            backgroundColor: theme.palette.secondary.dark,
        }
    },
}))(ListItem)

export const CustomDrawerSecondaryListButtonWithoutPadding = withStyles(theme => ({
    root: {
        ...theme.typography.paragraph3,
    },
    gutters: {
        // paddingLeft: 0,
        // paddingRight: 0,
    },
    button: {
        '&:hover': {
            color: theme.palette.secondary.contrastText,
            backgroundColor: theme.palette.secondary.light,
        },
        '&:active': {
            backgroundColor: theme.palette.secondary.dark,
        }
    },
}))(ListItem)

export const CustomDrawerTertiaryListButton = withStyles(theme => ({
    root: {
        ...theme.typography.paragraph3,
    },
    gutters: {
        paddingLeft: theme.spacing(4.5),
        paddingRight: theme.spacing(3),
    },
    button: {
        '&:hover': {
            color: theme.palette.tertiary.contrastText,
            backgroundColor: theme.palette.tertiary.light,
        },
        '&:active': {
            backgroundColor: theme.palette.tertiary.dark,
        }
    },
}))(ListItem)

export const CustomAccountSelect = withStyles(theme => ({
    select: {
        background: theme.palette.primaryGradient,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        ...theme.typography.paragraph4,
    },
}))(Select)


export const CustomAccountSelectParagraph1 = withStyles(theme => ({
    select: {
        ...theme.typography.paragraph1,
    },
}))(Select)

export const CustomAccountSelectParagraph4 = withStyles(theme => ({
    select: {
        ...theme.typography.paragraph4,
    },
}))(Select)

export const CustomAccountSelectParagraph5 = withStyles(theme => ({
    select: {
        ...theme.typography.paragraph5,
    },
}))(Select)

export const CustomButtonTextTransform = withStyles(theme => ({
    root: {
        textTransform: 'none'
    },
    disabled: {
        backgroundColor: getLightDarkGrayscaleColor(theme, 40, 40),
        color: `${theme.palette.primary.contrastText} !important`
    },
}))(Button)

export const CustomPrimaryBasicButton = withStyles(theme => ({
    root: {
        minWidth: theme.spacing(24),
        ...theme.typography.paragraph3,
        color: theme.palette.primary.main,
        transition: 'all 0.5s ease',
        '&:hover': {
            color: theme.palette.primary.light,
        },
        '&:active': {
            color: theme.palette.primary.dark,
        },
    },
}))(CustomButtonTextTransform)

export const CustomQuarternaryBasicButton = withStyles(theme => ({
    root: {
        minWidth: theme.spacing(24),
        ...theme.typography.paragraph4,
        color: theme.palette.quarternary.main,
        transition: 'all 0.5s ease',
        '&:hover': {
            color: theme.palette.quarternary.light,
        },
        '&:active': {
            color: theme.palette.quarternary.dark,
        },
    },
}))(CustomButtonTextTransform)

export const CustomSecondaryButtonParagraph5 = withStyles(theme => ({
    root: {
        minWidth: theme.spacing(24),
    },
    contained: {
        ...theme.typography.paragraph5,
        background: theme.palette.secondary.main,
        color: theme.palette.primary.contrastText,
        transition: 'all 0.5s ease',
        '&:hover': {
            background: theme.palette.secondary.light,
        },
        '&:active': {
            background: theme.palette.secondary.dark,
        },
    },
}))(CustomButtonTextTransform)

export const CustomRegisterButton = withStyles(theme => ({
    outlined: {
        ...theme.typography.body2,
        fontWeight: theme.typography.fontWeightMedium,
        background: theme.palette.primary.main,
        color: getLightDarkGrayscaleColor(theme, 100, 10),
        transition: 'all 0.5s ease',
        '&:hover': {
            background: theme.palette.primary.light,
        },
        '&:active': {
            background: theme.palette.primary.dark,
        }
    },
}))(CustomButtonTextTransform)

export const CustomLoginButton = withStyles(theme => ({
    text: {
        ...theme.typography.paragraph4,
        color: theme.palette.tertiary ? theme.palette.tertiary.main : theme.palette.primary.main,
        transition: 'all 0.5s ease',
        '&:hover': {
            color: theme.palette.tertiary ? theme.palette.tertiary.light : theme.palette.primary.main,
        },
        '&:active': {
            color: theme.palette.tertiary ? theme.palette.tertiary.dark : theme.palette.primary.main,
        }
    },
}))(CustomButtonTextTransform)

export const CustomBody1BoldButton = withStyles(theme => ({
    outlined: {
        ...theme.typography.body1,
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        background: theme.palette.primary.main,
        color: getLightDarkGrayscaleColor(theme, 100, 10),
        transition: 'all 0.5s ease',
        '&:hover': {
            background: theme.palette.primary.light,
        },
        '&:active': {
            background: theme.palette.primary.dark,
        }
    },
    text: {
        ...theme.typography.body1,
        // padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        color: theme.palette.primary.main,
        transition: 'all 0.5s ease',
        '&:hover': {
            color: theme.palette.primary.light,
        },
        '&:active': {
            color: theme.palette.primary.dark,
        }
    },
    disabled: {
        backgroundColor: theme.palette.divider,
        color: `${theme.palette.primary.contrastText} !important`
    },
}))(CustomButtonTextTransform)

export const CustomBody1SemiBoldButton = withStyles(theme => ({
    outlined: {
        ...theme.typography.body1,
        fontWeight: theme.typography.fontWeightSemiBold,
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        background: theme.palette.primary.main,
        color: getLightDarkGrayscaleColor(theme, 100, 10),
        transition: 'all 0.5s ease',
        '&:hover': {
            background: theme.palette.primary.light,
        },
        '&:active': {
            background: theme.palette.primary.dark,
        }
    },
}))(CustomButtonTextTransform)

export const CustomHeading2Button = withStyles(theme => ({
    outlined: {
        ...theme.typography.h2,
        fontWeight: theme.typography.fontWeightMedium,
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        background: theme.palette.primary.main,
        color: getLightDarkGrayscaleColor(theme, 100, 10),
        transition: 'all 0.5s ease',
        '&:hover': {
            background: theme.palette.primary.light,
        },
        '&:active': {
            background: theme.palette.primary.dark,
        }
    },
}))(CustomButtonTextTransform)

export const CustomHeading3Button = withStyles(theme => ({
    outlined: {
        ...theme.typography.h3,
        fontWeight: theme.typography.fontWeightMedium,
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        background: theme.palette.primary.main,
        color: getLightDarkGrayscaleColor(theme, 100, 10),
        transition: 'all 0.5s ease',
        '&:hover': {
            background: theme.palette.primary.light,
        },
        '&:active': {
            background: theme.palette.primary.dark,
        }
    },
}))(CustomButtonTextTransform)

export const CustomHeading3ButtonPrimary = withStyles(theme => ({
    outlined: {
        ...theme.typography.h3,
        fontWeight: theme.typography.fontWeightMedium,
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        background: theme.palette.primary.main,
        color: getLightDarkGrayscaleColor(theme, 100, 10),
        transition: 'all 0.5s ease',
        '&:hover': {
            background: theme.palette.primary.light,
        },
        '&:active': {
            background: theme.palette.primary.dark,
        }
    },
}))(CustomButtonTextTransform)
export const CustomHeading3ButtonSecondary = withStyles(theme => ({
    outlined: {
        ...theme.typography.h3,
        fontWeight: theme.typography.fontWeightMedium,
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        background: theme.palette.secondary.main,
        color: getLightDarkGrayscaleColor(theme, 100, 10),
        transition: 'all 0.5s ease',
        '&:hover': {
            background: theme.palette.secondary.light,
        },
        '&:active': {
            background: theme.palette.secondary.dark,
        }
    },
}))(CustomButtonTextTransform)
export const CustomHeading3ButtonTertiary = withStyles(theme => ({
    outlined: {
        ...theme.typography.h3,
        fontWeight: theme.typography.fontWeightMedium,
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        background: theme.palette.tertiary?.main ?? theme.palette.primary.main,
        color: getLightDarkGrayscaleColor(theme, 100, 10),
        transition: 'all 0.5s ease',
        '&:hover': {
            background: theme.palette.tertiary?.light ?? theme.palette.primary.light,
        },
        '&:active': {
            background: theme.palette.tertiary?.dark ?? theme.palette.primary.dark,
        }
    },
}))(CustomButtonTextTransform)
export const CustomHeading3ButtonQuarternary = withStyles(theme => ({
    outlined: {
        ...theme.typography.h3,
        fontWeight: theme.typography.fontWeightMedium,
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        background: theme.palette.quarternary?.dark ?? theme.palette.primary.main,
        color: getLightDarkGrayscaleColor(theme, 100, 10),
        transition: 'all 0.5s ease',
        '&:hover': {
            background: theme.palette.quarternary?.dark ?? theme.palette.primary.light,
        },
        '&:active': {
            background: theme.palette.quarternary?.dark ?? theme.palette.primary.dark,
        }
    },
}))(CustomButtonTextTransform)

export const CustomBodyPrimaryButtonPrimary = withStyles(theme => ({
    outlined: {
        ...theme.typography.body1,
        fontWeight: theme.typography.fontWeightMedium,
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        background: theme.palette.primary.main,
        color: getLightDarkGrayscaleColor(theme, 100, 10),
        transition: 'all 0.5s ease',
        '&:hover': {
            background: theme.palette.primary.light,
        },
        '&:active': {
            background: theme.palette.primary.dark,
        }
    },
}))(CustomButtonTextTransform)
export const CustomBodyPrimaryButtonSecondary = withStyles(theme => ({
    outlined: {
        ...theme.typography.body1,
        fontWeight: theme.typography.fontWeightMedium,
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        background: theme.palette.secondary.main,
        color: getLightDarkGrayscaleColor(theme, 100, 10),
        transition: 'all 0.5s ease',
        '&:hover': {
            background: theme.palette.secondary.light,
        },
        '&:active': {
            background: theme.palette.secondary.dark,
        }
    },
}))(CustomButtonTextTransform)
export const CustomBodyPrimaryButtonTertiary = withStyles(theme => ({
    outlined: {
        ...theme.typography.body1,
        fontWeight: theme.typography.fontWeightMedium,
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        background: theme.palette.tertiary?.main ?? theme.palette.primary.main,
        color: getLightDarkGrayscaleColor(theme, 100, 10),
        transition: 'all 0.5s ease',
        '&:hover': {
            background: theme.palette.tertiary?.light ?? theme.palette.primary.light,
        },
        '&:active': {
            background: theme.palette.tertiary?.dark ?? theme.palette.primary.dark,
        }
    },
}))(CustomButtonTextTransform)
export const CustomBodyPrimaryButtonQuarternary = withStyles(theme => ({
    outlined: {
        ...theme.typography.body1,
        fontWeight: theme.typography.fontWeightMedium,
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        background: theme.palette.quarternary?.dark ?? theme.palette.primary.main,
        color: getLightDarkGrayscaleColor(theme, 100, 10),
        transition: 'all 0.5s ease',
        '&:hover': {
            background: theme.palette.quarternary?.dark ?? theme.palette.primary.light,
        },
        '&:active': {
            background: theme.palette.quarternary?.dark ?? theme.palette.primary.dark,
        }
    },
}))(CustomButtonTextTransform)

export const CustomFormControlLabelPara3 = withStyles(theme => ({
    label: {
        ...theme.typography.paragraph3,
    },
}))(FormControlLabel)

export const CustomMobileStepper = withStyles(theme => ({
    dot: {
        height: theme.spacing(2),
        width: theme.spacing(2),
        backgroundColor: theme.palette.foreground,
        border: `1px solid ${theme.palette.text.disabled}`,
    },
    dotActive: {
        background: theme.palette.primaryGradient,
    }
}))(MobileStepper)

export const CustomAutoCompleteBodyPrimary = withStyles(theme => ({
    root: {
        ...theme.typography.body1,
    },
    paper: {
        backgroundColor: theme.palette.foreground,
    },
    option: {
        color: theme.palette.text.primary,
        ...theme.typography.paragraph2,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
        },
        '&:active': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.dark,
        },
        '&[aria-selected="true"]': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
            },
            '&:active': {
                backgroundColor: theme.palette.primary.dark,
            },
        },
        '&.Mui-focusVisible': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        }
    },
    noOptions: {
        color: theme.palette.text.primary,
        ...theme.typography.paragraph2,
    },
}))(Autocomplete)

export const CustomAutocomplete = withStyles(theme => ({
    root: {
        ...theme.typography.paragraph2,
    },
    paper: {
        backgroundColor: theme.palette.foreground,
    },
    option: {
        color: theme.palette.text.primary,
        ...theme.typography.paragraph2,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
        },
        '&:active': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.dark,
        },
        '&[aria-selected="true"]': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
            },
            '&:active': {
                backgroundColor: theme.palette.primary.dark,
            },
        },
        '&.Mui-focusVisible': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        }
    },
    noOptions: {
        color: theme.palette.text.primary,
        ...theme.typography.paragraph2,
    },
}))(Autocomplete)

export const CustomAutocompleteParagraph1 = withStyles(theme => ({
    root: {
        ...theme.typography.paragraph1,
    },
    option: {
        color: theme.palette.text.primary,
        ...theme.typography.paragraph1,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
        },
        '&:active': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.dark,
        },
        '&[aria-selected="true"]': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
            },
            '&:active': {
                backgroundColor: theme.palette.primary.dark,
            },
        },
        '&.Mui-focusVisible': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        }
    },
    noOptions: {
        color: theme.palette.text.primary,
        ...theme.typography.paragraph1,
    },
}))(CustomAutocomplete)

export const CustomAutocompleteParagraph5 = withStyles(theme => ({
    root: {
        ...theme.typography.paragraph5,
    },
    option: {
        color: theme.palette.text.primary,
        ...theme.typography.paragraph5,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
        },
        '&:active': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.dark,
        },
        '&[aria-selected="true"]': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
            },
            '&:active': {
                backgroundColor: theme.palette.primary.dark,
            },
        },
        '&.Mui-focusVisible': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        }
    },
    noOptions: {
        color: theme.palette.text.primary,
        ...theme.typography.paragraph5,
    },
}))(CustomAutocomplete)

export const CustomAutocompleteMultiple = withStyles(theme => ({
    root: {
        ...theme.typography.paragraph2,
    },
    paper: {
        backgroundColor: theme.palette.foreground,
    },
    option: {
        backgroundColor: theme.palette.foreground,
        color: theme.palette.text.primary,
        ...theme.typography.paragraph2,
        '&:hover': {
            backgroundColor: theme.palette.background,
        },
        '&[aria-selected="true"]': {
            backgroundColor: theme.palette.foreground,
            '&:hover': {
                backgroundColor: theme.palette.background,
            },
        },
        '&.Mui-focusVisible': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        }
    },
    noOptions: {
        color: theme.palette.text.primary,
        ...theme.typography.paragraph2,
    },
}))(Autocomplete)

export const CustomAutocompleteMultipleParagraph5 = withStyles(theme => ({
    root: {
        ...theme.typography.paragraph5,
        "& .MuiAutocomplete-listbox": {
            ...theme.typography.paragraph5,
        },
        '&.Mui-focusVisible': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        }
    },
    paper: {
        backgroundColor: theme.palette.foreground,
    },
    option: {
        backgroundColor: theme.palette.foreground,
        color: theme.palette.text.primary,
        ...theme.typography.paragraph5,
        '&:hover': {
            backgroundColor: theme.palette.background,
        },
        '&[aria-selected="true"]': {
            backgroundColor: theme.palette.foreground,
            '&:hover': {
                backgroundColor: theme.palette.background,
            },
        }
    },
    inputRoot: {
        ...theme.typography.paragraph5,
    },
    noOptions: {
        color: theme.palette.text.primary,
        ...theme.typography.paragraph5,
    },
}))(CustomAutocompleteMultiple)

export const CustomSlider = withStyles(theme => ({
    root: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
        width: '98%'
    },
    thumb: {
        '&[data-index="0"]': {
            marginLeft: '0px',
        },
    },
    markLabel: {
        '&[data-index="0"]': {
            transform: 'none'
        },
    }
}))(Slider)

export const CustomTextfieldParagraph1 = withStyles(theme => ({
    root: {
        '& > div': {
            ...theme.typography.paragraph1,
        }
    }
}))(TextField)

export const CustomTextfieldParagraph5 = withStyles(theme => ({
    root: {
        '& > div': {
            ...theme.typography.paragraph5,
        }
    }
}))(TextField)

export const CustomTextField = withStyles(theme => ({
    root: {
        '& input': {
            paddingLeft: `${theme.spacing(1)}px !important`,
            paddingRight: `${theme.spacing(1)}px !important`
        },
        '& textarea': {
            paddingLeft: `${theme.spacing(1)}px !important`,
            paddingRight: `${theme.spacing(1)}px !important`
        },
        '& > p': {
            paddingLeft: `${theme.spacing(1)}px !important`,
            paddingRight: `${theme.spacing(1)}px !important`
        },
    }
}))(TextField)

export const CustomTextFieldBody1Medium = withStyles(theme => ({
    root: {
        '& > div': {
            ...theme.typography.body1,
            fontWeight: theme.typography.fontWeightMedium,
        },
    }
}))(CustomTextField)

export const CustomTextFieldBody2Medium = withStyles(theme => ({
    root: {
        '& > div': {
            ...theme.typography.body2,
            fontWeight: theme.typography.fontWeightMedium,
        },
    }
}))(CustomTextField)

export const CustomTextFieldLabelMedium = withStyles(theme => ({
    root: {
        '& > div': {
            ...theme.typography.labelMedium ?? theme.typography.body1,
        },
    }
}))(CustomTextField)

export const CustomTeamAvatar = withStyles(theme => ({
    colorDefault: {
        backgroundColor: theme.palette.background,
        border: `2px solid ${theme.palette.text.primary}`,
    },
}))(Avatar)

export const CustomLinearProgress = withStyles(theme => ({
    colorPrimary: {
        backgroundColor: theme.palette.text.disabled,
    },
    barColorPrimary: {
        background: theme.palette.gradient4,
    },
}))(LinearProgress)

export const CustomSwitch = withStyles((theme) => ({
    root: {
        width: theme.spacing(4),
        height: theme.typography.paragraph5.fontSize,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.foreground,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.foreground,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: `calc(${theme.typography.paragraph3.fontSize} - 3px)`,
        height: `calc(${theme.typography.paragraph3.fontSize} - 3px)`,
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.contrastText,
    },
    track: {
        border: `1px solid ${theme.palette.text.disabled}`,
        borderRadius: theme.typography.paragraph3.fontSize / 2,
        opacity: 1,
        backgroundColor: theme.palette.text.disabled,
    },
    checked: {},
}))(Switch)

export const CustomSwitchTertiary = withStyles((theme) => ({
    root: {
        width: theme.spacing(4),
        height: theme.typography.paragraph5.fontSize,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.foreground,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.foreground,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.tertiary.main,
                borderColor: theme.palette.tertiary.main,
            },
        },
    },
    thumb: {
        width: `calc(${theme.typography.paragraph3.fontSize} - 3px)`,
        height: `calc(${theme.typography.paragraph3.fontSize} - 3px)`,
        boxShadow: 'none',
        backgroundColor: theme.palette.tertiary.contrastText,
    },
    track: {
        border: `1px solid ${theme.palette.text.disabled}`,
        borderRadius: theme.typography.paragraph3.fontSize / 2,
        opacity: 1,
        backgroundColor: theme.palette.text.disabled,
    },
    checked: {},
}))(Switch)

export const CustomTableCell = withStyles((theme) => ({
    head: {
        ...theme.typography.h3
    },
    body: {
        ...theme.typography.paragraph1
    }
}))(TableCell)

export const CustomAccordion = withStyles((theme) => ({
    root: {
        border: 'none',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.secondary.main
        },
    },
    expanded: {}
}))(Accordion)

export const CustomAccordionSummary = withStyles({
    root: {
        borderBottom: 'none',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(AccordionSummary)

export const CustomAccordionFolder = withStyles((theme) => ({
    root: {
        boxShadow: 'none',
        border: 'none',
        boxShadow: 'none',
        position: 'relative',
        transition: 'color 0.3s',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
            padding: 'auto',
        },
        /*
        '&:hover':  {
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.primary.contrastText
        },
        "&:hover svg": {
            color: theme.palette.primary.contrastText
        },
        */
    },
    selected: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.contrastText
    },
    expanded: {}
}))(Accordion)

export const CustomAccordionSummaryFolder = withStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        margin: '0px',
        minHeight: '0px',
        '&$expanded': {
            minHeight: 0,
            margin: 'auto',
        },
        '&::after': {
            background: theme.palette.secondary.main,
        },
    },
    content: {
        '&$expanded': {
            margin: '0',
        },
    },
    selected: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.contrastText
    },
    expanded: {},
}))(AccordionSummary)

export const CustomAccordionDetailsFolder = withStyles((theme) => ({
    root: {
        paddingTop: '0',
        paddingBottom: '0'
    },
    selected: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.contrastText
    },
}))(AccordionDetails)

export const CustomAccordionLandingSummary = withStyles({
    root: {
        borderBottom: 'none',
        marginBottom: -1,
        minHeight: 0,
        '&$expanded': {
            minHeight: 0,
        },
    },
    content: {
        margin: 0,
        '&$expanded': {
            margin: '0',
        },
    },
    expanded: {},
    expandIcon: {
        padding: 0
    },
})(AccordionSummary)

export const CustomAccordionDetails = withStyles((theme) => ({
    root: {
        padding: 0,
        '& > li': {
            width: '100%'
        }
    },
}))(AccordionDetails)

export const CustomAccordionSettings = withStyles((theme) => ({
    root: {
        border: 'none',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
        backgroundColor: getLightDarkGrayscaleColor(theme, '80', '90')
    },
    expanded: {}
}))(Accordion)

export const CustomAccordionSummarySettings = withStyles((theme) => ({
    root: {
        backgroundColor: getLightDarkGrayscaleColor(theme, '100', '80'),
        padding: 0,
        paddingRight: theme.spacing(1.5),
    },
    content: {
        padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
        margin: 0,
    },
    expanded: {
        margin: `0 !important`,
        minHeight: 'fit-content !important',
    },
    expandIcon: {
        marginRight: `-${theme.spacing(1)}px !important`
    }
}))(AccordionSummary)

export const CustomAccordionDetailsSettings = withStyles((theme) => ({
    root: {
        padding: `${theme.spacing(1)}px 0px`
    }
}))(AccordionDetails)

export const CustomFormControlLabelHeading3 = withStyles((theme) => ({
    label: {
        ...theme.typography.h3
    }
}))(FormControlLabel)

export const CustomFormControlLabelParagraph5 = withStyles((theme) => ({
    label: {
        ...theme.typography.paragraph5
    }
}))(FormControlLabel)

export const CustomFormControlLabelBodySecondary = withStyles((theme) => ({
    label: {
        ...theme.typography.body2
    }
}))(FormControlLabel)

export const CustomCheckBoxPrimaryHeading3 = withStyles((theme) => ({
    colorPrimary: {
        '&.Mui-checked': {
            color: theme.palette.tertiary.main ?? theme.palette.primary.main,
        }
    }
}))(Checkbox)

export const CustomCheckBoxTertiaryParagraph5 = withStyles((theme) => ({
    colorPrimary: {
        '&.Mui-checked': {
            color: theme.palette.tertiary.main ?? theme.palette.primary.main,
        }
    }
}))(Checkbox)

export const CustomCheckBoxQuarternaryParagraph5 = withStyles((theme) => ({
    colorPrimary: {
        '&.Mui-checked': {
            color: theme.palette.quarternary.main ?? theme.palette.primary.main,
        }
    }
}))(Checkbox)

