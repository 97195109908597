import { combineReducers } from 'redux'
import websiteLayoutReducer from './websiteLayoutReducer'
import layoutReducer from './layoutReducer'
import insightsReducer from './insightsReducer'
import leftSidepanelReducer from './leftSidepanelReducer'
import rightSidepanelReducer from './rightSidepanelReducer'
import loginRegisterReducer from './loginRegisterReducer'
import homeReducer from './homeReducer'
import exploreReducer from './exploreReducer'
import exploreDownloadProgressReducer from './exploreDownloadProgressReducer'
import exploreContextMenuReducer from './exploreContextMenuReducer'
import monitorReducer from './monitorReducer'
import optimizeReducer from './optimizeReducer'
import settingsDataReducer from './settingsDataReducer'
import searchReducer from './searchReducer'
import keplerGl from './keplerReducer'
import keplerCustomReducer from './keplerCustomReducer'
import jsStoreReducer from './jsStoreReducer'

const allReducers = combineReducers({
    websiteLayoutReducer,
    layoutReducer,
    insightsReducer,
    leftSidepanelReducer,
    rightSidepanelReducer,
    loginRegisterReducer,
    homeReducer,
    exploreReducer,
    exploreDownloadProgressReducer,
    exploreContextMenuReducer,
    monitorReducer,
    optimizeReducer,
    settingsDataReducer,
    searchReducer,
    keplerGl,
    keplerCustomReducer,
    jsStoreReducer,
})

export default allReducers