import * as actions from "../actions/actionTypes"

// Left Sidepanel
export const handleLeftSidepanelMobileToggle = () => ({
    type: actions.LEFT_DRAWER_MOBILE_TOGGLE
})

export const handleLeftSidepanel = () => ({
    type: actions.LEFT_SIDEPANEL_TOGGLE
})

// Right Sidepanel
export const handleRightSidepanel = (payload) => ({
    type: actions.RIGHT_SIDEPANEL_TOGGLE,
    payload
})

export const handleRightSidepanelActiveContent = (payload) => ({
    type: actions.RIGHT_SIDEPANEL_ACTIVE_CONTENT,
    payload
})

export const handleCreateVisitReport = (payload) => ({
    type: actions.CREATE_VISIT_REPORT,
    payload
})

export const handleButtonChange = (payload) => ({
    type: actions.BUTTON_CHANGE,
    payload
})

export const handleRenameReport = (payload) => ({
    type: actions.RENAME_REPORT,
    payload
})

export const handleRenameFolder = (payload) => ({
    type: actions.RENAME_FOLDER,
    payload
})

export const handleDeleteReport = (payload) => ({
    type: actions.DELETE_REPORT,
    payload
})

// Layout Theme
export const handleChangeTheme = () => ({
    type: actions.CHANGE_THEME
})

export const handleChangePalette = (payload) => ({
    type: actions.CHANGE_PALETTE,
    payload
})

// Insights
export const handleUpdateNetworkGraphHtml = (payload) => ({
    type: actions.UPDATE_NETWORK_GRAPH_HTML,
    payload
})

export const handleUpdateNetworkGraphLoading = (payload) => ({
    type: actions.UPDATE_NETWORK_GRAPH_LOADING,
    payload
})

export const handleUpdateBrandOptions = (payload) => ({
    type: actions.UPDATE_NETWORK_GRAPH_BRAND_OPTIONS,
    payload
})

export const handleUpdateBrandTypeOptions = (payload) => ({
    type: actions.UPDATE_NETWORK_GRAPH_BRAND_TYPE_OPTIONS,
    payload
})

export const handleUpdateTypeOptions = (payload) => ({
    type: actions.UPDATE_NETWORK_GRAPH_TYPE_OPTIONS,
    payload
})

// Login Register
export const handleToggleLoginRegisterModal = (payload) => ({
    type: actions.TOGGLE_MODAL,
    payload
})

export const handleChangeLoginRegistrationState = (payload) => ({
    type: actions.CHANGE_LOGIN_REGISTER_STATE,
    payload
})

export const handleChangeUserName = (payload) => ({
    type: actions.CHANGE_USERNAME,
    payload
})

export const handleChangeFirstLoginStatus = (payload) => ({
    type: actions.CHANGE_FIRST_LOGIN_STATUS,
    payload
})

export const handleChangeVersion = (payload) => ({
    type: actions.CHANGE_VERSION,
    payload
})

export const handleChangeSSOLogin = (payload) => ({
    type: actions.CHANGE_SSOLOGIN,
    payload
})

export const handleChangeCheckPartnerLogin = (payload) => ({
    type: actions.CHECK_PARTNER_LOGIN,
    payload
})

export const handleAddPartnerUserDetails = (payload) => ({
    type: actions.ADD_PARTNER_USER_DETAILS,
    payload
})

// Lovefield
export const handleAddConnectedDB = (payload) => ({
    type: actions.ADD_CONNECTED_DB,
    payload
})

// Home Dashboard
export const handleChangeHomeSelected = (payload) => ({
    type: actions.CHANGE_HOME_SELECTED,
    payload
})

export const handleUpdateHomeLoading = (payload) => ({
    type: actions.UPDATE_HOME_LOADING,
    payload
})

// Exploration Dashboard
export const handleAddExploreReportData = (payload) => ({
    type: actions.ADD_EXPLORE_REPORT_DATA,
    payload
})

export const handleAggregatedVisitationData = (payload) => ({
    type: actions.ADD_AGGREGATED_VISITATION_COUNT,
    payload
})

export const handleAddExploreMergedReportData = (payload) => ({
    type: actions.ADD_EXPLORE_MERGED_REPORT_DATA,
    payload
})

export const handleUpdateExploreReportData = (payload) => ({
    type: actions.UPDATE_EXPLORE_REPORT_DATA,
    payload
})

export const handleUpdateExploreSaveConfig = (payload) => ({
    type: actions.UPDATE_EXPLORE_SAVE_CONFIG,
    payload
})

export const handleSelectExploreSidepanel = (payload) => ({
    type: actions.SELECT_EXPLORE_SIDEPANEL,
    payload
})

export const handleUpdateExploreSidepanel = (payload) => ({
    type: actions.UPDATE_EXPLORE_SIDEPANEL,
    payload
})

export const handleAddRootFolderId = (payload) => ({
    type: actions.ADD_ROOT_FOLDER_ID,
    payload
})

export const handleUpdateExploreLoading = (payload) => ({
    type: actions.UPDATE_EXPLORE_LOADING,
    payload
})

export const handleUpdateExploreReportLoading = (payload) => ({
    type: actions.UPDATE_EXPLORE_REPORT_LOADING,
    payload
})

export const handleUpdateExploreReportError = (payload) => ({
    type: actions.UPDATE_EXPLORE_REPORT_ERROR,
    payload
})

export const handleUpdateParentChildExploreSidepanel = (payload) => ({
    type: actions.UPDATE_PARENT_CHILD_EXPLORE_SIDEPANEL,
    payload
})

export const handleAddExploreLayer = (payload) => ({
    type: actions.ADD_EXPLORE_LAYER,
    payload
})

export const handleUpdateExploreLayer = (payload) => ({
    type: actions.UPDATE_EXPLORE_LAYER,
    payload
})

export const handleDeleteExploreLayer = (payload) => ({
    type: actions.DELETE_EXPLORE_LAYER,
    payload
})

export const handleMergeExploreLayers = (payload) => ({
    type: actions.MERGE_LAYER,
    payload
})

export const handleUnMergeExploreLayers = (payload) => ({
    type: actions.UNMERGE_LAYER,
    payload
})

export const handleReorderExploreLayers = (payload) => ({
    type: actions.REORDER_EXPLORE_LAYERS,
    payload
})

export const handleUpdateMapStyle = (payload) => ({
    type: actions.UPDATE_MAP_STYLE,
    payload
})

// Exploration Data Download Progress
export const handleUpdateExploreDownloadProgress = (payload) => ({
    type: actions.UPDATE_EXPLORE_DOWNLOAD_PROGRESS,
    payload
})

export const handleUpdateLoadingText = () => ({
    type: actions.UPDATE_LOADING_TEXT
})

// Exploration Context Menu
export const handleOpenExploreContextMenu = (payload) => ({
    type: actions.OPEN_EXPLORE_CONTEXT_MENU,
    payload
})

export const handleCloseExploreContextMenu = (payload) => ({
    type: actions.CLOSE_EXPLORE_CONTEXT_MENU,
    payload
})

export const handleCreateFolder = (payload) => ({
    type: actions.CREATE_FOLDER,
    payload
})

export const handleAddReportToFolder = (payload) => ({
    type: actions.ADD_REPORT_TO_FOLDER,
    payload
})

export const handleRemoveReportFromFolder = (payload) => ({
    type: actions.REMOVE_REPORT_FROM_FOLDER,
    payload
})

export const handleReportMigrationToFolder = (payload) => ({
    type: actions.MIGRATE_REPORT_TO_ANOTHER_FOLDER,
    payload
})

export const handleIncrementLastFolderIndex = (payload) =>({
    type: actions.INCREMENT_LAST_FOLDER_INDEX,
    payload
})

export const handleDeleteFolder = (payload) => ({
    type: actions.DELETE_FOLDER,
    payload
})

// Monitoring Dashboard
export const handleUpdateMonitorSidepanel = (payload) => ({
    type: actions.UPDATE_MONITOR_SIDEPANEL,
    payload
})

export const handleUpdateMonitorLoading = (payload) => ({
    type: actions.UPDATE_MONITOR_LOADING,
    payload
})

export const handleUpdateMonitorDataLoading = (payload) => ({
    type: actions.UPDATE_MONITOR_DATA_LOADING,
    payload
})

export const handleUpdateMonitorDataError = (payload) => ({
    type: actions.UPDATE_MONITOR_DATA_ERROR,
    payload
})

export const handleUpdateParentChildMonitorSidepanel = (payload) => ({
    type: actions.UPDATE_PARENT_CHILD_MONITOR_SIDEPANEL,
    payload
})

export const handleUpdateMonitorDateRange = (payload) => ({
    type: actions.UPDATE_MONITOR_DATE_RANGE,
    payload
})

export const handleUpdateMonitorComparisonDateRange = (payload) => ({
    type: actions.UPDATE_MONITOR_COMPARSION_DATE_RANGE,
    payload
})

export const handleUpdateMonitorSummarizedData = (payload) => ({
    type: actions.UPDATE_MONITOR_SUMMARIZED_DATA,
    payload
})

export const handleUpdateMonitorComparisonSummarizedData = (payload) => ({
    type: actions.UPDATE_MONITOR_COMPARISON_SUMMARIZED_DATA,
    payload
})

export const handleUpdateMonitorData = (payload) => ({
    type: actions.UPDATE_MONITOR_DATA,
    payload
})

export const handleUpdateMonitorSummarizedCards = (payload) => ({
    type: actions.UPDATE_MONITOR_SUMMARIZED_CARDS,
    payload
})

export const handleUpdateMonitorCharts = (payload) => ({
    type: actions.UPDATE_MONITOR_CHARTS,
    payload
})

export const handleSelectMonitorSidepanel = (payload) => ({
    type: actions.SELECT_MONITOR_SIDEPANEL,
    payload
})

export const handleUpdateComparisionSelectedValue = (payload) => ({
    type: actions.UPDATE_ADSET_COMPARISION_SELECTED_VALUE,
    payload
})

export const handleUpdateComparisionSummarized = (payload) => ({
    type: actions.UPDATE_ADSET_COMPARISION_SUMMARIZED,
    payload
})

export const handleRemoveComparisionSummarized = () => ({
    type: actions.REMOVE_ADSET_COMPARISION_SUMMARIZED,
})

export const handleExpandMonitorSidepanel = (payload) => ({
    type: actions.EXPAND_LIST_MONITOR_SIDEPANEL,
    payload
})

export const handleUpdateMonitorSidepanelContent = (payload) => ({
    type: actions.UPDATE_MONITOR_SIDEPANEL_CONTENT,
    payload
})

// Optimize Dashboard
export const handleUpdateOptimizeDateRange = (payload) => ({
    type: actions.UPDATE_OPTIMIZE_DATE_RANGE,
    payload
})

export const handleUpdateOptimizeData = (payload) => ({
    type: actions.UPDATE_OPTIMIZE_DATA,
    payload
})

export const handleUpdateOptimizeSidepanel = (payload) => ({
    type: actions.UPDATE_OPTIMIZE_SIDEPANEL,
    payload
})

export const handleUpdateOptimizeLoading = (payload) => ({
    type: actions.UPDATE_OPTIMIZE_LOADING,
    payload
})

export const handleUpdateOptimizeDataLoading = (payload) => ({
    type: actions.UPDATE_OPTIMIZE_DATA_LOADING,
    payload
})

export const handleUpdateOptimizeDataError = (payload) => ({
    type: actions.UPDATE_OPTIMIZE_DATA_ERROR,
    payload
})

export const handleUpdateOptimizeCharts = (payload) => ({
    type: actions.UPDATE_OPTIMIZE_CHARTS,
    payload
})

export const handleUpdateParentChildOptimizeSidepanel = (payload) => ({
    type: actions.UPDATE_PARENT_CHILD_OPTIMIZE_SIDEPANEL,
    payload
})

export const handleSelectOptimizeSidepanel = (payload) => ({
    type: actions.SELECT_OPTIMIZE_SIDEPANEL,
    payload
})

export const handleExpandOptimizeSidepanel = (payload) => ({
    type: actions.EXPAND_LIST_OPTIMIZE_SIDEPANEL,
    payload
})

// Search Reducer
export const handleUpdateSearchQuery = (payload) => ({
    type: actions.CHANGE_SEARCH_QUERY,
    payload
})

export const handleUpdateSearchDatabase = (payload) => ({
    type: actions.CHANGE_SEARCH_DATABASE,
    payload
})

export const handleUpdateSearchData = (payload) => ({
    type: actions.UPDATE_SEARCH_DATA,
    payload
})

export const handleToggleSearchLoading = (payload) => ({
    type: actions.TOGGLE_SEARCH_LOADING,
    payload
})

export const handleUpdateChat = (payload) => ({
    type: actions.UPDATE_CHAT,
    payload
})

export const handleToggleSearchError = () => ({
    type: actions.TOGGLE_SEARCH_ERROR
})

// Settings Reducer
export const handleUpdateSettingsData = (payload) => ({
    type: actions.UPDATE_SETTINGS_DATA,
    payload
})

export const handleUpdateSettingsUserData = (payload) => ({
    type: actions.UPDATE_SETTINGS_USER_DATA,
    payload
})

export const handleUpdateSettingsCompanyData = (payload) => ({
    type: actions.UPDATE_SETTINGS_COMPANY_DATA,
    payload
})

export const handleUpdateSettingsPlanData = (payload) => ({
    type: actions.UPDATE_SETTINGS_PLAN_DATA,
    payload
})

export const handleUpdateSettingsSocialData = (payload) => ({
    type: actions.UPDATE_SETTINGS_SOCIAL_DATA,
    payload
})

export const handleChangeSettingsSelected = (payload) => ({
    type: actions.CHANGE_SETTINGS_SELECTED,
    payload
})

export const handleUpdateSettingsAccounts = (payload) => ({
    type: actions.UPDATE_SETTINGS_ACCOUNTS,
    payload
})

export const handleUpdateSettingsSocialAccounts = (payload) => ({
    type: actions.UPDATE_SETTINGS_SOCIAL_ACCOUNTS,
    payload
})

export const handleUpdateUploadedAudience = (payload) => ({
    type: actions.UPDATE_UPLOADED_AUDIENCE,
    payload
})

// Common
export const handleLogout = () => {
    localStorage.clear()
    return ({
        type: actions.LOGOUT
    })
}