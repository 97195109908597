import * as actions from "../actions/actionTypes"

const initialState = {
    "user": {},
    "company": {},
    "plan": {},
    "social": {},
    "accounts": [],
    "socialAccounts": {},
    "selected": 'none',
    "uploadedAudience": []
}

const settingsDataReducer = (state = initialState, action) => {
    switch(action.type) {
        case actions.UPDATE_SETTINGS_DATA:
            return {
                ...state,
                ...action.payload
            }
        case actions.UPDATE_SETTINGS_USER_DATA:
            return {
                ...state,
                "user": action.payload
            }
        case actions.UPDATE_SETTINGS_COMPANY_DATA:
            return {
                ...state,
                "company": action.payload
            }
        case actions.UPDATE_SETTINGS_PLAN_DATA:
            return {
                ...state,
                "plan": action.payload
            }
        case actions.UPDATE_SETTINGS_SOCIAL_DATA:
            return {
                ...state,
                "social": action.payload
            }
        case actions.CHANGE_SETTINGS_SELECTED:
            return {
                ...state,
                "selected": action.payload
            }
        case actions.UPDATE_SETTINGS_ACCOUNTS:
            return {
                ...state,
                "accounts": action.payload
            }
        case actions.UPDATE_SETTINGS_SOCIAL_ACCOUNTS:
                return {
                    ...state,
                    "socialAccounts": action.payload
                }
        case actions.UPDATE_UPLOADED_AUDIENCE:
            return {
                ...state,
                "uploadedAudience": action.payload
            }
        case actions.LOGOUT:
            return initialState
        default:
            return state
    }
}

export default settingsDataReducer