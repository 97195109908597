import { monitorChartLayoutConfig, summarizedCards } from "../../Dashboard/Content/Monitor/Config/monitorConfig"
import * as actions from "../actions/actionTypes"

const initialState = {
    sidepanel: [],
    loading: false,
    dataLoading: false,
    dataError: false,
    // selected: [],
    selected: 'none',
    selectedValue: {},
    adsetComparisionSelectedValue: [],
    adsetComparisionSummarized: {},
    dateRange: [],
    comparisonDateRange: [],
    indeterminate: [],
    expanded: [],
    summarized: {},
    comparisonSummarized: {},
    data: {},
    summarizedCards: summarizedCards.slice(0, 4),
    charts: [],
}

const getChildrenIds = (elem, id) => {
    var t = []
    if (elem.children) {
        for (var x of elem.children ?? []) {
            if (['pass', elem.id, x.id].includes(id)) {
                t.push(x.id)
                t = t.concat(getChildrenIds(x, 'pass'))
            }
        }
    }
    return t
}

const getParentIds = (items, id) => {
    const allIds = []
    items.forEach(item => {
        if (item.id === id) {
            allIds.push(item.id)
        } else if (item.children) {
            const ids = getParentIds(item.children, id)
            if (ids.length) {
                allIds.push(item.id)
            }
            ids.forEach(ele => allIds.push(ele))
        }
    })
    return allIds
}

const getValue = (value, obj, key = "id") => {
    if (obj[key] === value) {
        return obj
    } else {
        let keys = Object.keys(obj)
        for (let i = 0, len = keys.length; i < len; i++) {
            let k = keys[i]
            if (obj[k] && typeof obj[k] == 'object') {
                let found = getValue(value, obj[k], key)
                if (found) {
                    return found
                }
            }
        }
    }
}

const monitorReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.UPDATE_MONITOR_SIDEPANEL_CONTENT:
            if (action.payload.length > 1) {
                return {
                    ...state,
                    sidepanel: action.payload,
                    adsetComparisionSelectedValue: [],
                    adsetComparisionSummarized: {}
                }
            }
            return {
                ...state,
                sidepanel: action.payload,
                summarized: {},
                comparisonSummarized: {},
                data: {},
                adsetComparisionSelectedValue: [],
                adsetComparisionSummarized: {}
            }
        /*
        case actions.UPDATE_SETTINGS_SOCIAL_DATA:
            return {
                ...state,
                "sidepanel": [],
                "summarized": {},
                "data": {}
            }
        */
        case actions.UPDATE_MONITOR_SIDEPANEL: {
            let temp = { ...state.sidepanel }
            temp[action.payload.id] = action.payload
            return { ...state, sidepanel: temp }
        }
        case actions.UPDATE_MONITOR_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case actions.UPDATE_MONITOR_DATA_LOADING:
            return {
                ...state,
                dataLoading: action.payload,
                dataError: false
            }
        case actions.UPDATE_MONITOR_DATA_ERROR:
            return {
                ...state,
                dataLoading: false,
                dataError: action.payload,
                selected: 'none'
            }
        case actions.UPDATE_MONITOR_DATE_RANGE:
            return {
                ...state,
                dateRange: action.payload
            }
        case actions.UPDATE_MONITOR_COMPARSION_DATE_RANGE:
            return {
                ...state,
                comparisonDateRange: action.payload
            }
        case actions.UPDATE_MONITOR_SUMMARIZED_DATA:
            return {
                ...state,
                summarized: action.payload
            }
        case actions.UPDATE_MONITOR_COMPARISON_SUMMARIZED_DATA:
            return {
                ...state,
                comparisonSummarized: action.payload
            }
        case actions.UPDATE_MONITOR_DATA:
            return {
                ...state,
                data: { ...state.data, ...action.payload }
            }
        case actions.UPDATE_MONITOR_SUMMARIZED_CARDS:
            return {
                ...state,
                summarizedCards: action.payload
            }
        case actions.UPDATE_MONITOR_CHARTS:
            return {
                ...state,
                charts: action.payload
            }
        // case actions.SELECT_MONITOR_SIDEPANEL: {
        //     let temp = [...state.selected]
        //     let tempIndeterminate = []
        //     let tempChildrenIds = [...new Set(state.sidepanel.map(elem => getChildrenIds(elem, action.payload.id)).flat())]
        //     let tempParentIds = getParentIds(state.sidepanel, action.payload.id)

        //     if (state.selected.includes(action.payload.id)) {
        //         temp = temp.filter(ele => ![action.payload.id, ...tempChildrenIds].includes(ele))
        //     } else {
        //         temp.push(action.payload.id)
        //         temp = temp.concat(tempChildrenIds)
        //     }

        //     return {
        //         ...state,
        //         selected: temp,
        //         indeterminate: tempIndeterminate
        //     }
        // }
        case actions.SELECT_MONITOR_SIDEPANEL:
            var temp = getValue(action.payload, state.sidepanel) ?? {}
            let tempSummarized = monitorChartLayoutConfig[temp.objective] ?? monitorChartLayoutConfig["campaign"]
            return {
                ...state,
                selected: action.payload,
                selectedValue: temp,
                summarizedCards: tempSummarized.summarized,
                charts: tempSummarized.charts
            }
        case actions.UPDATE_ADSET_COMPARISION_SELECTED_VALUE:
            var adsetComparisionSelectedValue = state.adsetComparisionSelectedValue
            var temp = getValue(action.payload.selected, state.sidepanel, action.payload?.key) ?? {}
            var selectedValue = {}
            selectedValue[temp.id] = temp
            adsetComparisionSelectedValue.splice(action.payload.index, 1, selectedValue)
            return {
                ...state,
                adsetComparisionSelectedValue: adsetComparisionSelectedValue,
            }
        case actions.UPDATE_ADSET_COMPARISION_SUMMARIZED:
            return {
                ...state,
                adsetComparisionSummarized: { ...state.adsetComparisionSummarized, ...action.payload },
            }
        case actions.REMOVE_ADSET_COMPARISION_SUMMARIZED:
            return {
                ...state,
                adsetComparisionSummarized: {},
            }
        case actions.EXPAND_LIST_MONITOR_SIDEPANEL:
            return {
                ...state,
                "expanded": action.payload
            }
        case actions.LOGOUT:
            return initialState
        default:
            return state
    }
}

export default monitorReducer