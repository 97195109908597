import { processRowObject } from 'kepler.gl/processors'
import { multiLayerMappingReverse } from './exploreConfig'

export const getPivottableConfig = (id) => {
    switch (id) {
        case 0:
        case 8:
            return ({
                aggregatorName: "Count",
                rows: ['POI(s)'],
                cols: ['district'],
                vals: ['POI(s)'],
                hiddenAttributes: ["poiCode", "poiType", "h3index", "district_code", "city", "address1", "polygon", "poi_type", "poi_code", "prefix", "source_poi_code", "naics_nic_code", "latitude", "longitude", "h3resolution", "address_1", "locality", "pin_code", "building_type", "sub_category", "chain_id", "rlat", "rlon", "ingestion_date", "source_date", "validation_source_poi_name", "validation_source_latitude", "validation_source_longitude", "name_match_level", "validation_match_score", "reviews_count", "ratings", "prices", "validation_source_url", "pois_within", "similar_places", "validation_source_poi_code_primary", "validation_source_address", "validation_source_poi_code_secondary", "validation_source", "validation_point_to_polygon_distance", "poi_type_match_level", "final_source_to_use", "traffic_volume_by_hour", "last_activity_date", "open_close_hours", "expected_duration", "nval", "ubuffer", "range", "polygon_source", "polygon_area_sqm", "clon", "clat", "number_of_polygons_mapped", "polygon_buf", "polygon_area_sqm_buf", "source_category", "indexing_level", "dscore", "google_category", "validation_source_poi_type_list", "validation_source_poi_type", "place", "country"],
                hiddenFromDragDrop: ["poiCode", "poiType", "h3index", "district_code", "city", "address1", "polygon", "poi_type", "poi_code", "prefix", "source_poi_code", "naics_nic_code", "latitude", "longitude", "h3resolution", "address_1", "locality", "pin_code", "building_type", "sub_category", "chain_id", "rlat", "rlon", "ingestion_date", "source_date", "validation_source_poi_name", "validation_source_latitude", "validation_source_longitude", "name_match_level", "validation_match_score", "reviews_count", "ratings", "prices", "validation_source_url", "pois_within", "similar_places", "validation_source_poi_code_primary", "validation_source_address", "validation_source_poi_code_secondary", "validation_source", "validation_point_to_polygon_distance", "poi_type_match_level", "final_source_to_use", "traffic_volume_by_hour", "last_activity_date", "open_close_hours", "expected_duration", "nval", "ubuffer", "range", "polygon_source", "polygon_area_sqm", "clon", "clat", "number_of_polygons_mapped", "polygon_buf", "polygon_area_sqm_buf", "source_category", "indexing_level", "dscore", "google_category", "validation_source_poi_type_list", "validation_source_poi_type", "place", "country"],
                hiddenFromAggregators: ["poiCode", "poiType", "h3index", "district_code", "city", "address1", "polygon", "poi_type", "poi_code", "prefix", "source_poi_code", "naics_nic_code", "latitude", "longitude", "h3resolution", "address_1", "locality", "pin_code", "building_type", "sub_category", "chain_id", "rlat", "rlon", "ingestion_date", "source_date", "validation_source_poi_name", "validation_source_latitude", "validation_source_longitude", "name_match_level", "validation_match_score", "reviews_count", "ratings", "prices", "validation_source_url", "pois_within", "similar_places", "validation_source_poi_code_primary", "validation_source_address", "validation_source_poi_code_secondary", "validation_source", "validation_point_to_polygon_distance", "poi_type_match_level", "final_source_to_use", "traffic_volume_by_hour", "last_activity_date", "open_close_hours", "expected_duration", "nval", "ubuffer", "range", "polygon_source", "polygon_area_sqm", "clon", "clat", "number_of_polygons_mapped", "polygon_buf", "polygon_area_sqm_buf", "source_category", "indexing_level", "dscore", "google_category", "validation_source_poi_type_list", "validation_source_poi_type", "place", "country"],
            })
        case 3:
            return ({
                aggregatorName: "Integer Sum",
                rows: ['date'],
                cols: [],
                vals: ['total_cases'],
                hiddenAttributes: ['city', 'createdAt', 'h3index', 'h3resolution', 'id', 'lgd_district_id', 'lgd_district_name', 'lgd_state_id', 'lgd_state_name', 'name', 'poiCode', 'poiType', 'poicode', 'polygon', 'source', 'state', 'subType', 'updatedAt'],
                hiddenFromDragDrop: ['city', 'createdAt', 'h3index', 'h3resolution', 'id', 'lgd_district_id', 'lgd_district_name', 'lgd_state_id', 'lgd_state_name', 'name', 'poiCode', 'poiType', 'poicode', 'polygon', 'source', 'state', 'subType', 'total_cases', 'total_deaths', 'updatedAt'],
                hiddenFromAggregators: ['city', 'createdAt', 'date', 'district', 'h3index', 'h3resolution', 'id', 'lgd_district_id', 'lgd_district_name', 'lgd_state_id', 'lgd_state_name', 'name', 'poiCode', 'poiType', 'poicode', 'polygon', 'source', 'state', 'subType', 'updatedAt'],
            })
        case 4:
            return ({
                aggregatorName: "Integer Sum",
                rows: ['date'],
                cols: [],
                vals: ['totalVaccination'],
                hiddenAttributes: ['bad_flg_covaxin', 'bad_flg_covishield', 'female_vac', 'first_dose_admin', 'lgd_district_id', 'lgd_district_name', 'lgd_state_id', 'lgd_state_name', 'city', 'createdAt', 'h3index', 'h3resolution', 'id', 'name', 'poiCode', 'poiType', 'poicode', 'polygon', 'source', 'state', 'subType', 'totalVaccination', 'updatedAt'],
                hiddenFromDragDrop: ['bad_flg_covaxin', 'bad_flg_covishield', 'female_vac', 'first_dose_admin', 'lgd_district_id', 'lgd_district_name', 'lgd_state_id', 'lgd_state_name', 'male_vac', 'second_dose_admin', 'total_covaxin', 'total_covishield', 'total_individuals_registered', 'total_sessions_conducted', 'total_sites', 'trans_vac', 'city', 'createdAt', 'h3index', 'h3resolution', 'id', 'name', 'poiCode', 'poiType', 'poicode', 'polygon', 'source', 'state', 'subType', 'totalVaccination', 'updatedAt'],
                hiddenFromAggregators: ['bad_flg_covaxin', 'bad_flg_covishield', 'first_dose_admin', 'lgd_district_id', 'lgd_district_name', 'lgd_state_id', 'lgd_state_name', 'date', 'district', 'city', 'createdAt', 'h3index', 'h3resolution', 'id', 'name', 'poiCode', 'poiType', 'poicode', 'polygon', 'source', 'state', 'subType', 'updatedAt'],
            })
        case 5:
            return ({
                aggregatorName: "Integer Sum",
                rows: ['district'],
                cols: [],
                vals: ['congregation_count'],
                hiddenAttributes: ['address1', 'district_code', 'type', 'address2', 'areaSqm', 'city', 'createdAt', 'deletedAt', 'endTime', 'groupId', 'id', 'latitude', 'longitude', 'locality', 'name', 'startTime', 'updatedAt', 'ward', 'subDistrict', 'stateCode', 'polygon', 'population', 'source', 'h3index', 'h3resolution', 'households', 'pinCode', 'poiCode', 'poiType', 'radius', 'state', 'subType', 'zone'],
                hiddenFromDragDrop: ['address1', 'congregation_count', 'district_code', 'type', 'address2', 'areaSqm', 'city', 'createdAt', 'deletedAt', 'endTime', 'groupId', 'id', 'latitude', 'longitude', 'locality', 'name', 'startTime', 'updatedAt', 'ward', 'subDistrict', 'stateCode', 'polygon', 'population', 'source', 'h3index', 'h3resolution', 'households', 'pinCode', 'poiCode', 'poiType', 'radius', 'state', 'subType', 'zone'],
                hiddenFromAggregators: ['address1', 'district_code', 'type', 'address2', 'areaSqm', 'city', 'createdAt', 'deletedAt', 'endTime', 'groupId', 'id', 'latitude', 'longitude', 'locality', 'name', 'startTime', 'updatedAt', 'ward', 'subDistrict', 'stateCode', 'polygon', 'population', 'source', 'h3index', 'h3resolution', 'households', 'pinCode', 'poiCode', 'poiType', 'radius', 'state', 'subType', 'zone', 'date', 'district'],
            })
        case 6:
            return ({
                aggregatorName: "Integer Sum",
                rows: ['date'],
                // cols: ['district', 'POI(s)'],
                cols: [],
                vals: ['count'],
                hiddenAttributes: ['h3index', 'vendorSource', 'visit_duration', 'customer', 'district_code', 'poiCode', 'poicode', 'polygon', 'poiType', 'version'],
                hiddenFromDragDrop: ['h3index', 'vendorSource', 'visit_duration', 'customer', 'count', 'district_code', 'poiCode', 'poicode', 'polygon', 'poiType', 'version'],
                hiddenFromAggregators: ['h3index', 'vendorSource', 'visit_duration', 'customer', 'district_code', 'poiCode', 'poicode', 'polygon', 'poiType', 'version', 'date', 'district', 'POI(s)'],
            })
        case 9:
            return ({
                aggregatorName: "Integer Sum",
                rows: ['date'],
                cols: ['district', 'POI(s)'],
                vals: ['count'],
                hiddenAttributes: ['h3index', 'vendorSource', 'visit_duration', 'customer', 'district_code', 'poiCode', 'poicode', 'polygon', 'poiType', 'version'],
                hiddenFromDragDrop: ['h3index', 'vendorSource', 'visit_duration', 'customer', 'count', 'district_code', 'poiCode', 'poicode', 'polygon', 'poiType', 'version'],
                hiddenFromAggregators: ['h3index', 'vendorSource', 'visit_duration', 'customer', 'district_code', 'poiCode', 'poicode', 'polygon', 'poiType', 'version', 'date', 'district', 'POI(s)'],
            })
        default:
            return null
    }
}

export const districtColumns = ['district', 'district_code', 'poicode', 'districtCode', 'districtcode']
export const poiTypeColumns = ['poiType', 'poi_type']
export const hiddenColumns = ['geojson', 'polygon', 'lat', 'lng', 'lat', 'long', 'latitude', 'longitude', 'alt', 'altitude', 'h3', 'h3_index', 'h3index', 'poiCode', 'poicode', 'h3resolution', 'source', 'destination_lat', 'destination_long', 'clat', 'clon', 'rlat', 'rlon']

export const getLayersFilters = (type, config, subType = 'none', reportData) => {
    console.log(type, config, reportData);
    switch (type) {
        case 'poi':
            return {
                'layers': [
                    { layerId: `poi${config.id}PolygonLayer`, type: "geojson", columns: { "geojson": "polygon" }, colorFieldName: null, layerLabel: 'Poi Polygon' },
                    { layerId: `poi${config.id}H3Layer`, type: "hexagonId", columns: { "hex_id": "h3index" }, colorFieldName: null, layerLabel: 'Poi H3', hidden: true },
                ],
                'filters': [
                    { filterId: `poi${config.id}NameFilter`, type: '', column: "name", value: Array.from(new Set(reportData.filter(item => item?.name != null).map(item => item?.name))) },
                    { filterId: `poi${config.id}BrandFilter`, type: '', column: "brands", value: Array.from(new Set(reportData.filter(item => item?.brands != null).map(item => item?.brands))) },
                    { filterId: `poi${config.id}LocationFilter`, type: '', column: "district_code", value: config?.district_codes.split(',') },
                    { filterId: `poi${config.id}PoitypeFilter`, type: '', column: "poiType", value: config?.poitype.split(',') },
                ],
                'tooltips': ["name", "POI(s)", "brands", "address1"],
            }
        case 'mobility':
            let tempMobilityConfig = {
                'filters': [
                    { filterId: `mobility${config.id}DateFilter`, type: 'date', column: "date", value: getCurrentNextDay(config.start_date), enlarged: true },
                    { filterId: `mobility${config.id}LocationFilter`, type: '', column: "districtcode", value: config.district_codes.split(',') },
                ],
                'tooltips': ["date", "district", "changemoving"]
            }
            if (subType === 'admin.district_block') {
                tempMobilityConfig['layers'] = [{ layerId: `mobility${config.id}H3Layer`, type: "hexagonId", columns: { "hex_id": "poicode" }, colorFieldName: 'changemoving', layerLabel: 'Mobility H3' }]
            } else {
                tempMobilityConfig['layers'] = [{ layerId: `mobility${config.id}PolygonLayer`, type: "geojson", columns: { "geojson": "polygon" }, colorFieldName: 'changemoving', layerLabel: 'Mobility Polygon' }]
            }
            return tempMobilityConfig
        case 'wellbeing':
            let tempWbiConfig = {
                'filters': [
                    { filterId: `wellbeing${config.id}LocationFilter`, type: '', column: "districtcode", value: config.district_codes.split(',') },
                    { filterId: `wellbeing${config.id}SocialStatusFilter`, type: '', column: "wbipricesnormalizedcity", value: [0.1, 10] }
                ]
            }
            if (subType === 'WBI_census') {
                tempWbiConfig['layers'] = [{ layerId: `wellbeing${config.id}CensusLayer`, type: "geojson", columns: { "geojson": "polygon" }, colorFieldName: 'WBICensusNormalizedDistrict', layerLabel: 'Wellbeing Polygon' }]
                tempWbiConfig['tooltips'] = ['localityName', 'WBICensusNormalizedDistrict']
            } else if (subType === 'WBI_prices') {
                tempWbiConfig['layers'] = [{ layerId: `wellbeing${config.id}PricesLayer`, type: "hexagonId", columns: { "hex_id": "h3index" }, colorFieldName: 'wbipricesnormalizedcity', layerLabel: 'Wellbeing H3' }]
                tempWbiConfig['tooltips'] = ['locality', 'wbipricesnormalizedcity']
            } else if (subType === 'WBI_places') {
                tempWbiConfig['layers'] = [{ layerId: `wellbeing${config.id}PlacesLayer`, type: "hexagonId", columns: { "hex_id": "h3index" }, colorFieldName: 'WBIPlacesNormalizedCity', layerLabel: 'Wellbeing H3' }]
                tempWbiConfig['tooltips'] = ['localityName', 'WBIPlacesNormalizedCity']
            }
            return tempWbiConfig
        case 'covid_infection':
            return {
                'layers': [{ layerId: `covid_infection${config.id}Layer`, type: "geojson", columns: { "geojson": "polygon" }, colorFieldName: 'total_cases', layerLabel: 'Covid Infection Polygon' }],
                'filters': [
                    { filterId: `covid_infection${config.id}DateFilter`, type: 'date', column: "date", value: getCurrentNextDay(config.start_date) },
                    { filterId: `covid_infection${config.id}LocationFilter`, type: '', column: "poicode", value: config.district_codes.split(',') }
                ],
                'tooltips': ["date", "total_cases", "total_deaths"],
            }
        case 'covid_vaccination':
            return {
                'layers': [{ layerId: `covid_vaccination${config.id}Layer`, type: "geojson", columns: { "geojson": "polygon" }, colorFieldName: 'totalVaccination', layerLabel: 'Covid Vacination Polygon' }],
                'filters': [
                    { filterId: `covid_vaccination${config.id}DateFilter`, type: 'date', column: "date", value: getCurrentNextDay(config.start_date) },
                    { filterId: `covid_vaccination${config.id}LocationFilter`, type: '', column: "poicode", value: config.district_codes.split(',') }
                ],
                'tooltips': ["date", "male_vac", "female_vac", "trans_vac", "total_covaxin", "total_covishield", "totalVaccination"],
            }
        case 'congregation':
            let tempCongregationConfig = {
                'filters': [
                    { filterId: `congregation${config.id}DateFilter`, type: 'date', column: "date", value: getCurrentNextDay(config.start_date) },
                    { filterId: `congregation${config.id}LocationFilter`, type: '', column: "district_code", value: getCurrentNextDay(config.start_date) }
                ],
                'tooltips': ["date", "district", "congregation_count"],
            }
            if (subType === 'admin.district_block') {
                tempCongregationConfig['layers'] = [{ layerId: `congregation${config.id}H3Layer`, type: "hexagonId", columns: { "hex_id": "h3index" }, colorFieldName: 'congregation_count', layerLabel: 'Congregation Polygon' }]
            } else {
                tempCongregationConfig['layers'] = [{ layerId: `congregation${config.id}PolygonLayer`, type: "geojson", columns: { "geojson": "polygon" }, colorFieldName: 'congregation_count', layerLabel: 'Congregation Polygon' }]
            }
            return tempCongregationConfig
        case 'district_migration':
            return {
                'layers': [
                    { layerId: `district_migration${config.id}Layer`, type: "arc", columns: { "lat0": "origin_lat", "lng0": "origin_long", "lat1": "destination_lat", "lng1": "destination_long" }, colorFieldName: 'device_count', layerLabel: 'District Migration Arc' },
                    { layerId: `district_migration${config.id}SourcePointLayer`, type: "point", columns: { "lat": "origin_lat", "lng": "origin_long", "altitude": null }, colorFieldName: 'device_count', layerLabel: 'District Migration Source Point' },
                    { layerId: `district_migration${config.id}DestinationPointLayer`, type: "point", columns: { "lat": "destination_lat", "lng": "destination_long", "altitude": null }, colorFieldName: 'device_count', layerLabel: 'District Migration Destination Point' },
                ],
                'filters': [{ filterId: `district_migration${config.id}DateFilter`, type: 'date', column: "date", value: getCurrentNextDay(config.start_date) }],
                'tooltips': ["date", "district", "device_count"],
            }
        case 'poi_internal':
            return {
                'layers': [
                    { layerId: `poi_internal${config.id}H3Layer`, type: "hexagonId", columns: { "hex_id": "h3index" }, colorFieldName: null, layerLabel: 'Poi H3', hidden: true },
                    { layerId: `poi_internal${config.id}ArcLayer`, type: "arc", columns: { "lat0": "latitude", "lng0": "longitude", "lat1": "validation_source_latitude", "lng1": "validation_source_longitude" }, colorFieldName: null, layerLabel: 'Poi Arc' },
                    { layerId: `poi_internal${config.id}PointGoogleLayer`, type: "point", columns: { "lat": "validation_source_latitude", "lng": "validation_source_longitude", "altitude": null }, colorFieldName: null, layerLabel: 'Poi Google Point', color: [242, 19, 19] },
                    { layerId: `poi_internal${config.id}PointDestLayer`, type: "point", columns: { "lat": "clat", "lng": "clon", "altitude": null }, colorFieldName: null, layerLabel: 'Poi Centroid Point', color: [127, 0, 255] },
                    { layerId: `poi_internal${config.id}PointLayer`, type: "point", columns: { "lat": "latitude", "lng": "longitude", "altitude": null }, colorFieldName: null, layerLabel: 'Poi Actual/Google Point', color: [0, 204, 0] },
                    { layerId: `poi_internal${config.id}PolygonLayer`, type: "geojson", columns: { "geojson": "polygon" }, colorFieldName: null, layerLabel: 'Poi Polygon' },
                ],
                'filters': [
                    { filterId: `visitation${config.id}NameFilter`, type: '', column: "name", value: Array.from(new Set(reportData.filter(item => item?.name != null).map(item => item?.name))) },
                    { filterId: `visitation${config.id}BrandFilter`, type: '', column: "brands", value: Array.from(new Set(reportData.filter(item => item?.brands != null).map(item => item?.brands))) },
                    { filterId: `poi_internal${config.id}LocationFilter`, type: '', column: "district_code", value: config.district_codes.split(',') }
                ],
                'tooltips': ["name", "locality", "poiType", "distance_between_two_points"],
            }
        case 'visitation':
            let minCount = reportData[0]?.count;
            let maxCount = reportData[0]?.count;
            var count = reportData.forEach(ele => {
                if (ele?.count < minCount) {
                    minCount = ele?.count;
                } else if (ele?.count > maxCount) {
                    maxCount = ele?.count;
                }
            })
            
            return {
                'layers': [
                    { layerId: `visitation${config.id}H3Layer`, type: "hexagonId", columns: { "hex_id": "h3index" }, colorFieldName: 'count', layerLabel: 'Visitation H3', height: true, sizeField: { id: "count", name: "count", displayName: "count" } },
                    { layerId: `visitation${config.id}PolygonLayer`, type: "geojson", columns: { "geojson": "polygon" }, colorFieldName: null, layerLabel: 'Visitation Polygon', hidden: true },
                ],
                'filters': [
                    { filterId: `visitation${config.id}NameFilter`, type: '', column: "name", value: Array.from(new Set(reportData.filter(item => item?.name != null).map(item => item?.name))) },
                    { filterId: `visitation${config.id}BrandFilter`, type: '', column: "brands", value: Array.from(new Set(reportData.filter(item => item?.brands != null).map(item => item?.brands))) },
                    { filterId: `visitation${config.id}CountFilter`, type: '', column: "count", value: [minCount, maxCount] },
                    { filterId: `visitation${config.id}DateFilter`, type: 'date', column: "date", value: getCurrentNextDay(config.start_date), enlarged: true },
                    { filterId: `visitation${config.id}LocationFilter`, type: '', column: "district_code", value: config.district_codes.split(',') },
                ],
                'tooltips': ["name", "date", "count", "POI(s)", "brands"],
            }
        default:
            return {
                'layers': [],
                'filters': [],
                'tooltips': []
            }
    }
}

const modifyColumns = (fields) => {
    let columns = {
        "sname": "POI(s)"
    }
    let tempFields = fields.map(ele => {
        if (Object.keys(columns).includes(ele.name)) {
            return ({ ...ele, name: columns[ele.name], id: columns[ele.name], displayName: columns[ele.name] })
        }
        return ele
    })

    return tempFields
}

export const getData = (data) => {
    let { fields, rows } = processRowObject(data)
    fields = modifyColumns(fields)
    return ({ fields, rows })
}

export const getCurrentNextDay = (start_date) => {
    let startDate = new Date(start_date)
    let nextDay = new Date(start_date)
    nextDay.setDate(nextDay.getDate() + 1)

    startDate = startDate.getTime()
    nextDay = nextDay.getTime()

    return [startDate, nextDay]
}

const getFilter = (dataFields, filterId, dataId, column, value, type, enlarged) => {
    console.log(dataFields, filterId, dataId, column, value, type);
    if (!dataFields.includes(column)) {
        return {}
    }
    if (type === 'date') {
        return ({
            "id": filterId,
            "dataId": [dataId],
            "name": [column],
            "type": "timeRange",
            // "value": value,
            "enlarged": enlarged ?? false,
            "plotType": "histogram",
            "animationWindow": "incremental",
            "yAxis": null,
            "speed": 10
        })
    }
    if (column === 'brands') {
        return ({
            "id": filterId,
            "dataId": [dataId],
            "name": [column],
            "type": "multiSelect",
            //"value": [],
            "enlarged": false,
            "plotType": "histogram",
            "animationWindow": "free",
            "yAxis": null,
            "speed": 10
        })
    }
    return ({
        "id": filterId,
        "dataId": [dataId],
        "name": [column],
        "type": "multiSelect",
        "value": value,
        "enlarged": false,
        "plotType": "histogram",
        "animationWindow": "free",
        "yAxis": null,
        "speed": 10
    })
}

const getLayer = (dataFields, layerId, dataId, layerLabel, type, columns, colorFieldName, color, height, sizeField, hidden = false) => {
    if (colorFieldName && !dataFields.includes(colorFieldName)) {
        return {}
    }
    return ({
        "id": layerId,
        "type": type,
        "config": {
            "dataId": dataId,
            "label": layerLabel,
            color,
            "columns": columns,
            "hidden": false,
            "isVisible": hidden ? false : true,
            "visConfig": {
                "opacity": 0.8,
                "outline": false,
                "strokeColor": null,
                "colorRange": {
                    "name": "Custom Palette",
                    "type": "custom",
                    "category": "Custom",
                    "colors": [
                        "#fff7fb",
                        "#ece7f2",
                        "#d0d1e6",
                        "#a6bddb",
                        "#74a9cf",
                        "#3690c0",
                        "#0570b0",
                        "#034e7b"
                    ],
                    "reversed": false
                },
                "coverage": 1,
                "enable3d": height ?? false,
                "sizeRange": [
                    0,
                    500
                ],
                "coverageRange": [
                    0,
                    1
                ],
                "elevationScale": 5
            },
            "textLabel": [
                {
                    "field": null,
                    "color": [
                        255,
                        255,
                        255
                    ],
                    "size": 18,
                    "offset": [
                        0,
                        0
                    ],
                    "anchor": "start",
                    "alignment": "center"
                }
            ]
        },
        "visualChannels": {
            "colorField": colorFieldName ? { "name": colorFieldName } : colorFieldName,
            "colorScale": "quantile",
            "sizeField": sizeField ?? null,
            "sizeScale": "linear",
            "coverageField": null,
            "coverageScale": "linear"
        }
    })
}

const getInsightsLayer = (layerId, dataId, layerLabel, type, columns, colorFieldName, colorRange) => ({
    "id": layerId,
    "type": type,
    "config": {
        "dataId": dataId,
        "label": layerLabel,
        "columns": columns,
        "hidden": false,
        "isVisible": true,
        "visConfig": {
            "opacity": 0.8,
            "outline": false,
            "strokeColor": null,
            "coverage": 1,
            "enable3d": false,
            colorRange,
            "sizeRange": [
                0,
                500
            ],
            "coverageRange": [
                0,
                1
            ],
            "elevationScale": 5
        },
        "textLabel": [
            {
                "field": null,
                "color": [
                    255,
                    255,
                    255
                ],
                "size": 18,
                "offset": [
                    0,
                    0
                ],
                "anchor": "start",
                "alignment": "center"
            }
        ]
    },
    "visualChannels": {
        "colorField": colorFieldName ? { "name": colorFieldName } : colorFieldName,
        "colorScale": "quantile",
        "sizeField": null,
        "sizeScale": "linear",
        "coverageField": null,
        "coverageScale": "linear"
    }
})

const getConfig = (dataLabel, dataId, data, layers = [], filters = [], tooltips = []) => {
    let tempData = getData(data)
    tempData.fields.forEach((ele, idx) => {
        if (districtColumns.includes(ele.name)) {
            tempData.fields[idx] = { ...ele, "analyzerType": "STRING", "type": "string" }
        }
    })
    console.log(filters, tempData, data);
    let tempResult = {
        "datasets": [
            {
                "info": {
                    "label": dataLabel,
                    "id": dataId
                },
                "data": tempData
            }
        ],
        // "datasets": {
        //     "info": {
        //         "label": dataLabel,
        //         "id": dataId
        //     },
        //     "data": tempData
        // },
        "option": {
            "centerMap": false,
            "readOnly": true,
        },
        "config": {
            "version": "v1",
            "toggleMapControl": {
                "visibleLayers": { "show": false },
                "mapLegend": { "show": false },
                "toggle3d": { "show": false },
                "splitMap": { "show": false }
            },
            "config": {
                "mapStyle": {
                    "styleType": "dark"
                },
                "visState": {
                    "filters": filters.map(ele => getFilter(tempData.fields.map(ele => ele.name), ele.filterId, dataId, ele.column, ele.value, ele.type, ele.enlarged)),
                    "layers": layers.map(ele => getLayer(tempData.fields.map(ele => ele.name), ele.layerId, dataId, ele.layerLabel, ele.type, ele.columns, ele.colorFieldName, ele.color ?? [41, 163, 234], ele.height, ele.sizeField, ele.hidden)),
                    "interactionConfig": {
                        "tooltip": {
                            "fieldsToShow": { [dataId]: tooltips },
                            "enabled": true
                        },
                        "brush": {
                            "size": 0.5,
                            "enabled": false
                        },
                        "coordinate": {
                            "enabled": false
                        }
                    },
                    "layerBlending": "normal",
                    "splitMaps": [

                    ],
                    "animationConfig": {
                        "currentTime": null,
                        "speed": 1
                    }
                }
            }
        }
    }
    if (dataLabel.toLowerCase().includes('visitation')) {
        tempResult['config']['config']['mapState'] = {
            "pitch": 50,
            "bearing": 24,
            "isSplit": false,
            "initialState": {},
            "altitude": 1.5,
            "maxZoom": 24,
            "minZoom": 0,
            "maxPitch": 60,
            "minPitch": 0,
            "dragRotate": true
        }
    }

    return tempResult
}
const getInsightsConfig = (dataLabel, dataId, data, layers = [], filters = [], tooltips = []) => ({
    "datasets": {
        "info": {
            "label": dataLabel,
            "id": dataId
        },
        "data": processRowObject(data)
    },
    "option": {
        "centerMap": false,
        "readOnly": true,
    },
    "config": {
        "version": "v1",
        "toggleMapControl": {
            "visibleLayers": { "show": false },
            "mapLegend": { "show": false },
            "toggle3d": { "show": false },
            "splitMap": { "show": false }
        },
        "config": {
            "mapStyle": {
                "styleType": "dark"
            },
            "visState": {
                "filters": filters.map(ele => getFilter(ele.filterId, dataId, ele.column, ele.value, ele.type)),
                "layers": layers.map(ele => getInsightsLayer(ele.layerId, dataId, ele.layerLabel, ele.type, ele.columns, ele.colorFieldName, ele.colorRange)),
                "interactionConfig": {
                    "tooltip": {
                        "fieldsToShow": { [dataId]: tooltips },
                        "enabled": true
                    },
                    "brush": {
                        "size": 0.5,
                        "enabled": false
                    },
                    "coordinate": {
                        "enabled": false
                    }
                },
                "layerBlending": "normal",
                "splitMaps": [

                ],
                "animationConfig": {
                    "currentTime": null,
                    "speed": 1
                }
            }
        }
    }
})

export const mergedDatasetConfig = (data, config, mergedReportTypeId) => {
    let tempId = `merged_${config.id}`
    let tempMergedReportTypes = multiLayerMappingReverse[mergedReportTypeId]
    let [layers, filters, tooltips] = [[], [], []]
    tempMergedReportTypes.split('-').forEach(ele => {
        let tempConfig = getLayersFilters(ele, config, config.poitype, data)
        layers.push(...tempConfig.layers)
        filters.push(...tempConfig.filters)
        tooltips.push(...tempConfig.tooltips)
    })

    return getConfig("Merged Data", tempId, data, layers, filters, tooltips)
}

export const multipleDatasetsConfig = (existingConfig, newConfig) => ({
    "datasets": [...existingConfig.datasets, ...newConfig.datasets],
    // "datasets": {
    //     "info": {
    //         "label": dataLabel,
    //         "id": dataId
    //     },
    //     "data": tempData
    // },
    "option": {
        "centerMap": false,
        "readOnly": true,
    },
    "config": {
        "version": "v1",
        "toggleMapControl": {
            "visibleLayers": { "show": false },
            "mapLegend": { "show": false },
            "toggle3d": { "show": false },
            "splitMap": { "show": false }
        },
        "config": {
            "mapState": {
                ...newConfig['config']['config']['mapState']
            },
            "mapStyle": {
                "styleType": "dark"
            },
            "visState": {
                "filters": [...existingConfig.config.config.visState.filters, ...newConfig.config.config.visState.filters],
                "layers": [...existingConfig.config.config.visState.layers, ...newConfig.config.config.visState.layers],
                "interactionConfig": {
                    "tooltip": {
                        "fieldsToShow": { ...existingConfig.config.config.visState.interactionConfig.tooltip.fieldsToShow, ...newConfig.config.config.visState.interactionConfig.tooltip.fieldsToShow },
                        "enabled": true
                    },
                    "brush": {
                        "size": 0.5,
                        "enabled": false
                    },
                    "coordinate": {
                        "enabled": false
                    }
                },
                "layerBlending": "normal",
                "splitMaps": [

                ],
                "animationConfig": {
                    "currentTime": null,
                    "speed": 1
                }
            }
        }
    }
})

export const getIdValue = (val, getReportType = false) => {
    const expression = /(poi|visitation|mobility|wellbeing|covid_infection|covid_vaccination|congregation|district_migration|poi_internal)(\d+)/i
    const match = expression.exec(val)

    if (match) {
        return match[getReportType ? 1 : 2]
    }
    return null
}

export const poiLayerConfig = (data, config) => {
    let tempId = `poi_${config.id}`
    let { layers = [], filters = [], tooltips = [] } = getLayersFilters('poi', config, config.poitype, data)

    return getConfig("POI Data", tempId, data, layers, filters, tooltips)
}

export const mobilityLayerConfig = (data, config) => {
    let tempId = `mobility_${config.id}`
    let { layers = [], filters = [], tooltips = [] } = getLayersFilters('mobility', config, config.poitype, data)

    return getConfig("Mobility Data", tempId, data, layers, filters, tooltips)
}

export const wellbeingLayerConfig = (data, config) => {
    let tempId = `wellbeing_${config.id}`
    let { layers = [], filters = [], tooltips = [] } = getLayersFilters('wellbeing', config, config.poitype, data)

    return getConfig("Wellbeing Data", tempId, data, layers, filters, tooltips)
}

export const covidInfectionLayerConfig = (data, config) => {
    let tempId = `covid_infection_${config.id}`
    let { layers = [], filters = [], tooltips = [] } = getLayersFilters('covid_infection', config)

    return getConfig("Covid Infection Data", tempId, data, layers, filters, tooltips)
}

export const covidVaccinationLayerConfig = (data, config) => {
    let tempId = `covid_vaccination_${config.id}`
    let { layers = [], filters = [], tooltips = [] } = getLayersFilters('covid_vaccination', config)

    return getConfig("Covid Vacination Data", tempId, data, layers, filters, tooltips)
}

export const congregationLayerConfig = (data, config) => {
    let tempId = `congregation_${config.id}`
    let { layers = [], filters = [], tooltips = [] } = getLayersFilters('congregation', config, config.poitype)

    return getConfig("Congregation Data", tempId, data, layers, filters, tooltips)
}

export const districtMigrationLayerConfig = (data, config) => {
    let tempId = `district_migration_${config.id}`
    let { layers = [], filters = [], tooltips = [] } = getLayersFilters('district_migration', config)

    return getConfig("Cogregation Data", tempId, data, layers, filters, tooltips)
}

export const poiInternalLayerConfig = (data, config) => {
    let tempId = `poi_internal_${config.id}`
    let { layers = [], filters = [], tooltips = [] } = getLayersFilters('poi_internal', config, config.poitype, data)

    return getConfig("Poi Internal Data", tempId, data, layers, filters, tooltips)
}

export const visitationLayerConfig = (data, config) => {
    let tempId = `visitation_${config.id}`
    let { layers = [], filters = [], tooltips = [] } = getLayersFilters('visitation', config, config.poitype, data)

    return getConfig("Visitation Data", tempId, data, layers, filters, tooltips)
}

export const searchPoiLayerConfig = (data) => {
    let tempId = `search_poi`
    let layers = [{ layerId: `searchPoiH3Layer`, type: "hexagonId", columns: { "hex_id": "h3index" }, colorFieldName: null, layerLabel: 'Search Poi H3' }]
    let filters = []
    let tooltips = ["district", "poiType"]

    return getConfig("Search POI Data", tempId, data, layers, filters, tooltips)
}

export const insightMapConfig = (data, layers, tooltips) => {
    let tempId = `insights_data`
    return getInsightsConfig("Insights Data", tempId, data, layers, [], tooltips)
}